import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import {
    ApolloClient,
    useApolloClient,
    useMutation,
    useQuery,
    useSubscription
} from "@apollo/client";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { getLastAnalyticsUpdateDateString } from "../../../common/Helpers";
import { GENERATE_ANALYTICS_TASK } from "../../../graphql/mutations";
import {
    GET_LOCAL_ANALYTICS_STATE,
    GET_ME,
    GET_PLATFORM_CONFIG,
    GET_PRODUCTS_BY_USER_ORGANIZATION,
    GET_PROJECTS_BY_PRODUCT,
    GET_REVISIONS,
    GET_USER_ANALYTICS_QUERY_ANALYTIC
} from "../../../graphql/queries";
import {
    AnalyticsQueryConfig,
    AnalyticsQueryTask,
    AnalyticsQueryTaskFetchState,
    AnalyticsQueryType,
    AnalyticsQueryVariables,
    AnalyticsStateData,
    AnalyticsTaskResult,
    AthenaQueryResult,
    AthenaTaskQueryResult,
    MeData,
    PlatformConfigData
} from "../../../models/types";
import { AnalyticsDateInput } from "../../simple/analytics/DateInput";
import { TASK_READY_SUBSCRIPTION } from "../../../graphql/subscriptions";
import {
    clearFunnelResults,
    hasFunnelSelection,
    toggleFunnelSelectionMode,
    updateAthenaTask
} from "../../../common/AnalyticsHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFilter,
    faPlay,
    faQuestionCircle
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { AnalyticsState } from "../../../models/common";
import styles from "./AnalyticsManager.module.scss";
import cx from "classnames";

const AnalyticsManagerImplementation = () => {
    const client = useApolloClient();
    const navigate = useNavigate();
    const {
        analyticsEnabled,
        analyticsTimestamps,
        analyticsFunnelSelectionMode,
        analyticsProducts,
        analyticsProjects,
        analyticsRevisions,
        analyticsBuilds
    } = useLocalAnalyticsState();
    const [allowQuery, updateAllowQuery] = useState(true);
    const [loading, updateLoading] = useState(false);
    const [processing, updateProcessing] = useState(false);
    const { data: { getPlatformConfig } = {} } =
        useQuery<PlatformConfigData>(GET_PLATFORM_CONFIG);
    const [getAnalyticsTask] = useMutation<
        AnalyticsTaskResult,
        AnalyticsQueryVariables
    >(GENERATE_ANALYTICS_TASK);
    const { data: { me } = {} } = useQuery<MeData>(GET_ME, {
        fetchPolicy: "cache-only"
    });

    useEffect(() => {
        if (
            analyticsTimestamps.startTimestamp !== -1 &&
            analyticsTimestamps.endTimestamp === -1
        ) {
            updateAllowQuery(false);
        } else if (
            analyticsTimestamps.startTimestamp === -1 &&
            analyticsTimestamps.endTimestamp !== -1
        ) {
            updateAllowQuery(false);
        } else {
            updateAllowQuery(true);
        }
    }, [analyticsTimestamps]);

    const refetchCtrQueries = async () => {
        try {
            await client.refetchQueries({
                include: [
                    GET_PRODUCTS_BY_USER_ORGANIZATION,
                    GET_PROJECTS_BY_PRODUCT,
                    GET_REVISIONS,
                    GET_USER_ANALYTICS_QUERY_ANALYTIC
                ]
            });
        } catch (error) {
            console.log("[DEBUG] refetchCtrQueries error ", error);
        }
    };

    const updateAnalyticsResult = useCallback(
        (
            client: ApolloClient<any>,
            taskReadyNotification: AthenaTaskQueryResult
        ) => {
            const updateSubQueryResult = (
                client: ApolloClient<any>,
                data: AnalyticsState,
                subQueryResult: AthenaQueryResult
            ) => {
                if (!data.analyticsTask) {
                    console.log(
                        "[DEBUG] No analytics task to update, exiting..."
                    );
                    return;
                }
                const currentTask = JSON.parse(
                    JSON.stringify(data.analyticsTask)
                );
                client.writeQuery<AnalyticsStateData, AnalyticsStateData>({
                    query: GET_LOCAL_ANALYTICS_STATE,
                    data: {
                        analyticsState: {
                            ...data,
                            analyticsTask: {
                                ...currentTask,
                                completedCount: subQueryResult.completedCount
                            }
                        }
                    }
                });
            };

            const { success, taskId, queryType, results, allComplete } =
                taskReadyNotification as AthenaTaskQueryResult;
            if (success) {
                const analyticsData = client.readQuery<AnalyticsStateData>({
                    query: GET_LOCAL_ANALYTICS_STATE
                });
                if (
                    analyticsData &&
                    analyticsData.analyticsState?.analyticsTask !== undefined
                ) {
                    if (
                        analyticsData.analyticsState.analyticsTask.taskId ===
                        taskId
                    ) {
                        if (results.length > 0) {
                            switch (queryType) {
                                case AnalyticsQueryType.AllCtr: {
                                    if (!allComplete && success) {
                                        updateLoading(false);
                                        updateProcessing(true);
                                    } else if (allComplete && success) {
                                        updateLoading(false);
                                        updateProcessing(false);
                                        updateAthenaTask(client, undefined);
                                        refetchCtrQueries();
                                    }
                                    break;
                                }
                                case AnalyticsQueryType.TimeAverages:
                                case AnalyticsQueryType.TimeDistribution:
                                case AnalyticsQueryType.HeatmapCombined:
                                case AnalyticsQueryType.HeatmapPortrait:
                                case AnalyticsQueryType.HeatmapLandscape:
                                    const result =
                                        results[0] as AthenaQueryResult;
                                    updateSubQueryResult(
                                        client,
                                        analyticsData.analyticsState,
                                        result
                                    );
                                    break;
                                default:
                                    console.log(
                                        "[DEBUG] Analytics mode for " +
                                            queryType +
                                            " not implemented"
                                    );
                                    break;
                            }
                        }
                    }
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useSubscription(TASK_READY_SUBSCRIPTION, {
        variables: { userId: me?.id },
        skip: me === undefined,
        onData: ({
            data: { data: { taskReadyNotification } = {} },
            client
        }) => {
            updateAnalyticsResult(client, taskReadyNotification);
        }
    });

    if (!analyticsEnabled) {
        return null;
    }

    const lastAnalyticsUpdate = getLastAnalyticsUpdateDateString();

    const taskQuery = async () => {
        try {
            if (!getPlatformConfig) {
                console.log(
                    "[DEBUG] no platform config to allow running query"
                );
                return;
            }
            updateLoading(true);
            const config: AnalyticsQueryConfig = {
                apiVersion: getPlatformConfig.analyticsApiVersion,
                eventList: [],
                dates: analyticsTimestamps,
                includeDayFunnel: false,
                includeBuildPreview: false,
                includeBuildComparison: false,
                querySets: []
            };
            const result = await getAnalyticsTask({
                variables: {
                    // configs: [
                    //     JSON.stringify({
                    //         type: AnalyticsQueryType.AllCtr,
                    //         dates: analyticsTimestamps
                    //     })
                    // ]
                    input: config
                }
            });
            if (result.data?.generateAnalyticsTask.taskId) {
                const task: AnalyticsQueryTask = {
                    taskId: result.data?.generateAnalyticsTask.taskId,
                    type: AnalyticsQueryType.AllCtr,
                    isReady: false,
                    fetchStatus: AnalyticsQueryTaskFetchState.Querying
                };
                updateAthenaTask(client, task);
            }
        } catch (error) {
            console.log("[DEBUG] taskQuery error ", error);
        }
    };

    const renderTooltip = (tooltip: string, ...props: any[]) => (
        <Tooltip id="button-tooltip" {...props}>
            {tooltip}
        </Tooltip>
    );

    return (
        <div className={cx("text-inverse", styles.mainManager)}>
            <Row className="align-items-center">
                <Col className="d-flex mb-2 justify-content-start">
                    {analyticsFunnelSelectionMode && (
                        <OverlayTrigger
                            overlay={renderTooltip(
                                "Go to funnel view with the selected items"
                            )}
                            delay={{ show: 250, hide: 100 }}
                            rootClose={true}
                            rootCloseEvent="click"
                        >
                            <span className="d-inline-block">
                                {!hasFunnelSelection(
                                    analyticsProducts,
                                    analyticsProjects,
                                    analyticsRevisions,
                                    analyticsBuilds
                                ) ? (
                                    <Button
                                        className={cx(
                                            "me-2",
                                            styles.disabledHover,
                                            styles.funnelButton
                                        )}
                                        variant="secondary"
                                        disabled
                                    >
                                        <FontAwesomeIcon
                                            icon={faPlay}
                                            size="1x"
                                        />
                                    </Button>
                                ) : (
                                    <Button
                                        className={cx(
                                            "me-2",
                                            styles.funnelButton
                                        )}
                                        variant="analytics"
                                        onClick={(event: SyntheticEvent) => {
                                            event.stopPropagation();
                                            clearFunnelResults(client);
                                            navigate("/analytics");
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faPlay}
                                            size="1x"
                                        />
                                    </Button>
                                )}
                            </span>
                        </OverlayTrigger>
                    )}
                    <OverlayTrigger
                        overlay={renderTooltip("Toggle Funnel Selection Mode")}
                        delay={{ show: 250, hide: 100 }}
                        rootClose={true}
                        rootCloseEvent="click"
                    >
                        <Button
                            className={cx("me-2", styles.playButton)}
                            variant={
                                analyticsFunnelSelectionMode
                                    ? "analytics"
                                    : "secondary"
                            }
                            onClick={(event: SyntheticEvent) => {
                                event.stopPropagation();
                                toggleFunnelSelectionMode(
                                    client,
                                    !analyticsFunnelSelectionMode
                                );
                            }}
                        >
                            <FontAwesomeIcon icon={faFilter} size="1x" />
                        </Button>
                    </OverlayTrigger>
                    <div
                        className={styles.datePickerContainer}
                        style={{ position: "relative" }}
                    >
                        <AnalyticsDateInput />
                    </div>
                    <OverlayTrigger
                        overlay={renderTooltip(
                            `Last Analytics Update: ${lastAnalyticsUpdate}`
                        )}
                        delay={{ show: 250, hide: 100 }}
                    >
                        <FontAwesomeIcon
                            icon={faQuestionCircle}
                            className={styles.analyticsLastUpdate}
                            size="xs"
                        />
                    </OverlayTrigger>
                    <OverlayTrigger
                        overlay={renderTooltip(
                            "Update CTR Query using the selected time range"
                        )}
                        delay={{ show: 250, hide: 100 }}
                        rootClose={true}
                        rootCloseEvent="click"
                    >
                        <Button
                            onClick={(event: SyntheticEvent) => {
                                event.stopPropagation();
                                taskQuery();
                            }}
                            className={styles.updateButton}
                            disabled={!allowQuery || loading || processing}
                        >
                            {loading ? "Loading..." : ""}
                            {processing ? "Processing..." : ""}
                            {loading || processing ? "" : "Update"}
                        </Button>
                    </OverlayTrigger>
                </Col>
            </Row>
        </div>
    );
};

export const AnalyticsManager = React.memo(AnalyticsManagerImplementation);
