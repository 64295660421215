import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { FunnelProductsDetails } from "./FunnelProductsDetails";
import { FunnelProjectDetails } from "./FunnelProjectDetails";
import { FunnelRevisionDetails } from "./FunnelRevisionDetails";
import { FunnelBuildDetails } from "./FunnelBuildDetails";
import { FunnelNetworkBuildDetails } from "./FunnelNetworkBuildDetails";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { hasExtraSelection } from "../../../common/AnalyticsHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faExclamationTriangle,
    faScaleBalanced
} from "@fortawesome/free-solid-svg-icons";

export const QuerySelection = () => {
    const analyticsState = useLocalAnalyticsState();
    const [showDetails, updateShowDetails] = useState(true);
    const { hasOrphans, orphans } = hasExtraSelection(analyticsState);
    const [showOrphanWarning, setShowOrphanWarning] = useState(false);

    useEffect(() => {
        setShowOrphanWarning(hasOrphans);
    }, [hasOrphans]);

    return (
        <Card className="mb-3">
            <Card.Header
                className="d-flex"
                onClick={() => updateShowDetails(!showDetails)}
            >
                <h5 className="text-inverse-75 d-flex align-items-center mouseHover">
                    Query Selection
                </h5>
            </Card.Header>
            <Card.Body
                style={{ height: "313px", overflow: "auto" }}
                className="text-inverse"
            >
                <FunnelProductsDetails />
                <FunnelProjectDetails />
                <FunnelRevisionDetails />
                <FunnelBuildDetails />
                <FunnelNetworkBuildDetails />
            </Card.Body>
            {showOrphanWarning && (
                <Card.Footer className="pb-3">
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="text-warning"
                        size="1x"
                    />
                    <span className="bread-text text-inverse ms-2">
                        Some selected data is not included in the query because
                        an item is set to comparison mode (
                        <FontAwesomeIcon
                            icon={faScaleBalanced}
                            size="xs"
                            color="lightgreen"
                        />
                        ), and only the children of that item are queried.
                        Re-configure the comparison or ignore this message.
                    </span>
                </Card.Footer>
            )}
        </Card>
    );
};
