import React, { FC, ReactElement, useEffect, useState } from "react";
import { DSVRowArray } from "d3";
import { loadCsv, pastelStepColor } from "../../common/AnalyticsHelpers";
import { Card, Col, InputGroup, Row } from "react-bootstrap";
import { OverlayBadge } from "../simple/OverlayBadge";
import { AnalyticsQueryType, AthenaDatasetResult } from "../../models/types";
import { ChartConfiguration } from "chart.js";
import { ChartJsWrapper } from "./ChartJsWrapper";
import cx from "classnames";

interface Props {
    data: AthenaDatasetResult[];
}

const MultiTimeLineChartSegmentedImplementation: FC<Props> = ({ data }) => {
    const [csvData, updateCsvData] = useState<DSVRowArray[]>([]);
    const [showMax, updateShowMax] = useState(true);

    useEffect(() => {
        const fetchCsvData = async () => {
            const csvDatas: DSVRowArray[] = [];
            for (let i = 0; i < data.length; i++) {
                const element = data[i];

                for (let j = 0; j < element.dataset.length; j++) {
                    const dataset = element.dataset[j];
                    if (
                        dataset.success &&
                        dataset.message &&
                        dataset.type === AnalyticsQueryType.TimeDistribution
                    ) {
                        csvDatas.push(await loadCsv(dataset.message));
                    }
                }
            }
            updateCsvData(csvDatas);
        };
        fetchCsvData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (csvData.length === 0) {
        return null;
    }

    const elements: ReactElement[] = [];
    for (let k = 0; k < csvData.length; k++) {
        if (csvData[k] === undefined) {
            continue;
        }
        let filteredData = csvData[k].filter(
            element => element.sequence !== undefined
        );
        if (!showMax) {
            filteredData = filteredData.filter(
                element => element.interval !== "60+"
            );
        }
        const sortedData = filteredData.sort((a, b) =>
            // @ts-ignore
            a.interval > b.interval ? 1 : -1
        );
        let maxSequence = 1;
        for (let i = 0; i < csvData[k].length; i++) {
            const sequence = Number(csvData[k][i].sequence);
            if (sequence > maxSequence) {
                maxSequence = sequence;
            }
        }
        for (let i = 1; i <= maxSequence; i++) {
            const labels: string[] = [];
            const totals: number[] = [];
            let chartLabel: string | undefined = undefined;

            for (let j = 0; j < sortedData.length; j++) {
                const label = sortedData[j].interval;
                const sequence = Number(sortedData[j].sequence);
                const total = Number(sortedData[j].total);
                if (sequence === i && label && sequence) {
                    if (!chartLabel) {
                        chartLabel = sortedData[j].eventName;
                    }
                    labels.push(label);
                    totals.push(total);
                }
            }
            const config: ChartConfiguration = {
                type: "bar",
                data: {
                    labels: labels,
                    datasets: [
                        {
                            type: "bar",
                            label: chartLabel,
                            data: totals,
                            backgroundColor: pastelStepColor(i / maxSequence, 1)
                        }
                    ]
                },
                options: {
                    maintainAspectRatio: false
                }
            };
            elements.push(
                <Col
                    md={Math.floor(12 / maxSequence)}
                    key={"chart-" + k + "-" + i}
                    className=""
                >
                    <div className="d-flex justify-content-center align-items-center">
                        <div
                            className={cx("ps-2 bread-text", {
                                "mt-3": k > 0
                            })}
                        >
                            {data[k].datasetName}
                        </div>
                    </div>
                    <div style={{ maxHeight: "600px" }}>
                        <ChartJsWrapper config={config} />
                    </div>
                </Col>
            );
        }
    }

    return (
        <Card className="text-inverse mt-4">
            <Card.Header>
                <Row className="d-flex align-items-center">
                    <Col md={8} className=" text-inverse-75">
                        <h5>Time Distribution</h5>
                    </Col>
                    <Col
                        md={4}
                        className="d-flex justify-content-center align-items-center"
                    >
                        <InputGroup className="custom-input-background d-flex justify-content-end me-3">
                            <InputGroup.Checkbox
                                title="Show data points collected after 60 seconds"
                                checked={showMax}
                                onChange={() => {
                                    updateShowMax(!showMax);
                                }}
                            />
                            <InputGroup.Text className="bread-text text-inverse-75 p-0">
                                Show 60+
                            </InputGroup.Text>
                        </InputGroup>
                        <OverlayBadge
                            badgeHeader="?"
                            badgeVariant="secondary"
                            className=""
                            overlayText="Time distribution excludes duplicates (etc), so it might not match funnel results exactly."
                        ></OverlayBadge>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Row>{elements}</Row>
            </Card.Body>
        </Card>
    );
};

export const MultiTimeLineChartSegmented = React.memo(
    MultiTimeLineChartSegmentedImplementation
);
