import React from "react";
import { Product } from "../../../models/types";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FunnelProduct } from "../../simple/analytics/FunnelProduct";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import styles from "./FunnelProductsDetails.module.scss";

const FunnelProductsDetailsImplementation = () => {
    const { analyticsProducts } = useLocalAnalyticsState();

    if (analyticsProducts.length === 0) {
        return null;
    }

    const products = analyticsProducts
        .map((product: Product, index: number) => (
            <FunnelProduct key={index} product={product} />
        ))
        .filter(product => product !== null);

    return (
        <>
            {products.length > 0 && (
                <div className={`mb-3 ${styles.container}`}>
                    <Row className="d-flex align-items-center no-wrap mb-2">
                        <Col className={styles.title}>
                            <b className="text-inverse-75">Selected products</b>
                        </Col>
                        <Col className={styles.angles}>
                            <OverlayTrigger
                                placement="left"
                                overlay={
                                    <Tooltip id="show-more">
                                        Expand window to show Analytics data
                                        columns
                                    </Tooltip>
                                }
                            >
                                <FontAwesomeIcon icon={faAnglesRight} />
                            </OverlayTrigger>
                        </Col>
                    </Row>
                    {products}
                </div>
            )}
        </>
    );
};

export const FunnelProductsDetails = React.memo(
    FunnelProductsDetailsImplementation
);
