import React, { useState } from "react";
import { BatchBuild } from "../../../models/types";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { QueryDetailsBuildModal } from "../../modals/QueryDetailsBuildModal";
import { FunnelBatchBuild } from "../../simple/analytics/FunnelBatchBuild";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import styles from "./FunnelProductsDetails.module.scss";

const FunnelBuildDetailsImplementation = () => {
    const {
        analyticsBuilds,
        analyticsRevisions,
        analyticsProjects,
        analyticsProducts
    } = useLocalAnalyticsState();
    const [selectedItem, setSelectedItem] = useState<BatchBuild | undefined>(
        undefined
    );
    const [showModal, setShowModal] = useState(false);

    const handleShow = (item: BatchBuild) => {
        setSelectedItem(item);
        setShowModal(true);
    };

    const handleClose = () => {
        setSelectedItem(undefined);
        setShowModal(false);
    };

    if (analyticsBuilds.length === 0) {
        return null;
    }
    const builds = analyticsBuilds
        .map((build: BatchBuild) => {
            if (
                analyticsProducts.some(
                    product => build.revision.project.product?.id === product.id
                )
            ) {
                return null;
            }
            if (
                analyticsProjects.some(
                    project => build.revision.project.id === project.id
                )
            ) {
                return null;
            }
            if (
                analyticsRevisions.some(
                    revision => build.revision.id === revision.id
                )
            ) {
                return null;
            }
            return (
                <FunnelBatchBuild
                    key={build.id}
                    build={build}
                    handleShow={handleShow}
                />
            );
        })
        .filter(build => build !== null);

    return (
        <>
            <div className={styles.container}>
                <Row className="mb-2">
                    {builds.length > 0 && (
                        <>
                            <Col className={styles.title}>
                                <b className="text-inverse-75">
                                    Selected Builds
                                </b>
                            </Col>
                            <Col className={styles.angles}>
                                <OverlayTrigger
                                    placement="left"
                                    overlay={
                                        <Tooltip id="show-more">
                                            Expand window to show Analytics data
                                            columns
                                        </Tooltip>
                                    }
                                >
                                    <FontAwesomeIcon icon={faAnglesRight} />
                                </OverlayTrigger>
                            </Col>
                        </>
                    )}
                </Row>
                {builds}
            </div>
            {selectedItem ? (
                <QueryDetailsBuildModal
                    batchBuild={selectedItem}
                    showModal={showModal}
                    hideModal={handleClose}
                />
            ) : null}
        </>
    );
};

export const FunnelBuildDetails = React.memo(FunnelBuildDetailsImplementation);
