import React, { Component } from "react";
import { DSVRowArray } from "d3";
import {
    AnalyticsQueryType,
    AthenaQueryResult,
    ComparisonData
} from "../../../models/types";
import { loadCsv } from "../../../common/AnalyticsHelpers";
import { NetworkBatchComparisonElement } from "./NetworkBatchComparisonElement";

interface State {
    csvData: DSVRowArray | undefined;
}

interface Props {
    dataset: AthenaQueryResult[];
}

export class NetworkBuildComparison extends Component<Props> {
    state: State = {
        csvData: undefined
    };

    shouldComponentUpdate(_: Readonly<Props>, nextState: Readonly<State>) {
        return this.state.csvData !== nextState.csvData;
    }

    componentDidMount(): void {
        const { dataset } = this.props;
        const funnelData = dataset.find(
            element =>
                element.type === AnalyticsQueryType.NetworkBuildComparisonFunnel
        );
        if (funnelData?.success && funnelData.message) {
            loadCsv(funnelData.message)
                .then(result => {
                    this.setState({ csvData: result });
                })
                .catch(error => console.log("[DEBUG] error ", error));
        }
    }

    render() {
        const { csvData } = this.state;
        if (!csvData || csvData.length === 0) {
            return null;
        }

        const batches: any = {};
        for (let i = 0; i < csvData.length; i++) {
            const element: ComparisonData = {
                batch_id: csvData[i].batch_id || "",
                build_id: csvData[i].build_id || "",
                adStart: Number(csvData[i].adStart) || 0,
                ctaBtnPressed: Number(csvData[i].ctaBtnPressed) || 0,
                CTR: Number(csvData[i].CTR) || 0
            };
            if (!batches.hasOwnProperty(element.batch_id)) {
                batches[element.batch_id] = {
                    elements: [element],
                    adStart: element.adStart,
                    ctaBtnPressed: element.ctaBtnPressed,
                    average: element.ctaBtnPressed / element.adStart
                };
            } else {
                batches[element.batch_id].elements.push(element);
                batches[element.batch_id].adStart += element.adStart;
                batches[element.batch_id].ctaBtnPressed +=
                    element.ctaBtnPressed;
                batches[element.batch_id].average =
                    batches[element.batch_id].ctaBtnPressed /
                    batches[element.batch_id].adStart;
            }
        }

        const hasMultipleBatches = Object.keys(batches).length > 1;
        let bestBatch = "";
        let bestBatchAverage = 0;
        let worstBatch = "";
        let worstBatchAverage = 0;
        let bestNetworkBuild = undefined;
        let worstNetworkBuild = undefined;
        for (const key in batches) {
            if (batches[key].average > bestBatchAverage || bestBatch === "") {
                bestBatch = key;
                bestBatchAverage = batches[key].average;
            } else if (
                batches[key].average < worstBatchAverage ||
                worstBatch === ""
            ) {
                worstBatch = key;
                worstBatchAverage = batches[key].average;
            }
            for (let i = 0; i < batches[key].elements.length; i++) {
                if (!bestNetworkBuild && !worstNetworkBuild) {
                    bestNetworkBuild = batches[key].elements[i];
                    worstNetworkBuild = batches[key].elements[i];
                    continue;
                }
                if (batches[key].elements[i].CTR > bestNetworkBuild.CTR) {
                    bestNetworkBuild = batches[key].elements[i];
                } else if (
                    batches[key].elements[i].CTR < worstNetworkBuild.CTR
                ) {
                    worstNetworkBuild = batches[key].elements[i];
                }
            }
        }

        const batchElements = [];
        for (const key in batches) {
            batchElements.push(
                <NetworkBatchComparisonElement
                    key={key}
                    data={batches[key]}
                    batchId={key}
                    bestBatch={key === bestBatch}
                    worstBatch={key === worstBatch}
                    bestNetworkBuild={bestNetworkBuild}
                    worstNetworkBuild={worstNetworkBuild}
                    multipleBatches={hasMultipleBatches}
                />
            );
        }
        return <div className="mt-2">{batchElements}</div>;
    }
}
