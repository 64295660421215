import React, { FC, useEffect, useState } from "react";
import { Button, FormControl, InputGroup, Modal } from "react-bootstrap";
import { Project, ProjectSettings } from "../../models/types";
import { SlackInfoMessage } from "../simple/SlackInfoMessage";
import { ProjectConfig } from "../views/settings/ProjectConfig";
import { useLocalState } from "../../graphql/hooks";
import "./Modals.scss";

interface Props {
    project: Project;
    show: boolean;
    onSave: (settings: ProjectSettings) => void;
    onClose: () => void;
}

export const ProjectSettingsModal: FC<Props> = ({
    show,
    onSave,
    onClose,
    project
}) => {
    const [name, updateName] = useState(project.name);
    const [channel, updateChannel] = useState(project.slackChannel);
    const [id, updateId] = useState(project.gitProjectId);
    const [gitProjectName, updateGitProjectName] = useState(
        project.gitProjectName
    );
    const [gitProjectBranch, updateBranch] = useState(project.gitProjectBranch);
    const { useDarkMode } = useLocalState();

    useEffect(() => {
        updateName(project.name);
        updateId(project.gitProjectId);
        updateGitProjectName(project.gitProjectName);
        updateBranch(project.gitProjectBranch);
        updateChannel(project.slackChannel);
    }, [project]);

    return (
        <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            className={useDarkMode ? "modal-dark" : ""}
        >
            <Modal.Header closeButton>
                <Modal.Title>{project.name} settings</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body p-4">
                <InputGroup className="mb-3">
                    <InputGroup.Text>Project Name</InputGroup.Text>
                    <FormControl
                        defaultValue={name}
                        onChange={(event: any) => {
                            updateName(event.target.value);
                        }}
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text>Git Project Id</InputGroup.Text>
                    <FormControl
                        defaultValue={id?.toString()}
                        onChange={(event: any) => {
                            updateId(Number(event.target.value));
                        }}
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text>Git Project Name</InputGroup.Text>
                    <FormControl
                        defaultValue={gitProjectName}
                        onChange={(event: any) => {
                            updateGitProjectName(event.target.value);
                        }}
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text>Git Project Branch</InputGroup.Text>
                    <FormControl
                        defaultValue={gitProjectBranch}
                        onChange={(event: any) => {
                            updateBranch(event.target.value);
                        }}
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text>Slack Channel</InputGroup.Text>
                    <FormControl
                        defaultValue={channel}
                        onChange={(event: any) => {
                            updateChannel(event.target.value);
                        }}
                    />
                </InputGroup>
                <SlackInfoMessage />
                <Button
                    className="my-2"
                    onClick={() =>
                        onSave({
                            projectId: project.id,
                            name: name,
                            gitProjectId: id,
                            slackChannel: channel,
                            gitProjectName: gitProjectName,
                            gitProjectBranch: gitProjectBranch
                        })
                    }
                >
                    Save
                </Button>
                <ProjectConfig project={project} />
            </Modal.Body>
            {/* <Modal.Footer className="d-flex justify-content-center">
                <Button
                    onClick={() =>
                        onSave({
                            projectId: project.id,
                            name: name,
                            gitProjectId: id,
                            slackChannel: channel,
                            gitProjectName: gitProjectName,
                            gitProjectBranch: gitProjectBranch
                        })
                    }
                >
                    Save
                </Button>
            </Modal.Footer> */}
        </Modal>
    );
};
