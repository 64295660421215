import React, { useState } from "react";
import { Card, Collapse, ListGroup, ListGroupItem } from "react-bootstrap";
import {
    UserInvitation,
    UserInvitationsData,
    UserInvitationsVariables
} from "../../../models/types";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_PENDING_USER_INVITATIONS } from "../../../graphql/queries";

export const Invitations = () => {
    const [fetched, updateFetched] = useState(false);
    const [showUsedInvitations, updateShowUsedInvitations] = useState(false);

    const { data: { getInvitations } = {} } = useQuery<
        UserInvitationsData,
        UserInvitationsVariables
    >(GET_PENDING_USER_INVITATIONS, { variables: { pending: true } });

    const [
        getUsedInvitations,
        // @ts-ignore
        { data: { getInvitations: usedInvitations } = {} }
    ] = useLazyQuery(GET_PENDING_USER_INVITATIONS, {
        variables: { pending: false }
    });

    const hasExpired = (date: string) => {
        const expirationDate = new Date(date);
        const nowDate = new Date();
        return expirationDate < nowDate;
    };

    const pendingInvitationItems = getInvitations?.map(
        (invitation: UserInvitation, index: number) => (
            <ListGroupItem key={index} variant="primary">
                {hasExpired(invitation.dateExpired) ? (
                    <span className="text-danger">
                        {invitation.email} expired at {invitation.dateExpired}
                    </span>
                ) : (
                    <span>
                        {invitation.email} expires at {invitation.dateExpired}
                    </span>
                )}
            </ListGroupItem>
        )
    );

    const usedInvitationItems = usedInvitations?.map(
        (invitation: UserInvitation, index: number) => (
            <ListGroupItem key={index} variant="success">
                {invitation.email} attached to user{" "}
                {invitation.user.displayName} ({invitation.user.id})
            </ListGroupItem>
        )
    );

    const onUsedClick = () => {
        if (!fetched) {
            getUsedInvitations();
            updateFetched(true);
        }
        updateShowUsedInvitations(!showUsedInvitations);
    };

    return (
        <>
            <Card className="inner-card text-inverse m-3 p-0">
                <Card.Header>Pending Invitations</Card.Header>
                <Card.Body>
                    <ListGroup>{pendingInvitationItems}</ListGroup>
                </Card.Body>
            </Card>
            <Card className="inner-card text-inverse m-3 p-0">
                <Card.Header onClick={onUsedClick}>
                    Used Invitations
                </Card.Header>
                <Collapse in={showUsedInvitations && usedInvitationItems}>
                    <div>
                        <Card.Body>
                            <ListGroup>{usedInvitationItems}</ListGroup>
                        </Card.Body>
                    </div>
                </Collapse>
            </Card>
        </>
    );
};
