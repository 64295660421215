import React, { FC, useState } from "react";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { Col, Collapse, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { AnalyticsFunnelViewSelectionToggle } from "../../buttons/analytics/AnalyticsFunnelViewSelectionToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { getTree } from "../../../common/AnalyticsHelpers";
import { DailyBadges } from "./DailyBadges";
import { UserQueryBadges } from "./UserQueryBadges";
import { Revision } from "../../../models/types";
import { AnalyticsBatchBuilds } from "../../modals/analytics/AnalyticsBatchBuilds";
import { AnalyticsFunnelViewComparisonToggle } from "../../buttons/analytics/AnalyticsFunnelViewComparisonToggle";
import styles from "./FunnelBatchBuild.module.scss";

interface Props {
    revision: Revision;
}

const FunnelRevisionImplementation: FC<Props> = ({ revision }) => {
    const analyticsState = useLocalAnalyticsState();
    const myTree = getTree(analyticsState, Number(revision.id), "revisions");
    const [show, setShow] = useState(
        (myTree?.selection?.builds && myTree.selection.builds.length > 0) ||
            false
    );

    return (
        <div className={styles.container}>
            <Row className="my-1 align-items-center ps-2 g-0">
                <Col
                    className={`${styles.selectionToggleCol} d-flex align-items-center`}
                >
                    <AnalyticsFunnelViewSelectionToggle
                        reference="revision"
                        className="mx-2"
                        revision={revision}
                    />
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 100, hide: 100 }}
                        overlay={
                            <Tooltip id="funnel-revision-tooltip">
                                Revision name
                            </Tooltip>
                        }
                    >
                        <span
                            onClick={() => setShow(!show)}
                            className="ps-2 mouseHover bread-text"
                        >
                            {revision.name}
                        </span>
                    </OverlayTrigger>
                    <AnalyticsFunnelViewComparisonToggle
                        reference="revision"
                        className="ms-2"
                        revision={revision}
                    />
                    <FontAwesomeIcon
                        onClick={() => setShow(!show)}
                        className="mx-2 mouseHover color-secondary"
                        icon={show ? faChevronUp : faChevronDown}
                        size="xs"
                    />
                </Col>
                <Col className={styles.analyticsCol}>
                    <DailyBadges
                        reference="revision"
                        referenceId={revision.id}
                    />
                </Col>
                <Col className={styles.analyticsCol}>
                    <UserQueryBadges
                        reference="revision"
                        referenceId={revision.id}
                    />
                </Col>
                <Col className={styles.infoIconCol}></Col>
            </Row>
            <Collapse in={show}>
                <div>
                    <AnalyticsBatchBuilds revision={revision} />
                </div>
            </Collapse>
        </div>
    );
};

export const FunnelRevision = React.memo(FunnelRevisionImplementation);
