import { FC, useEffect, useState } from "react";
import { Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faPlay, faRotate } from "@fortawesome/free-solid-svg-icons";
import { SimpleDropdown } from "../../dropdowns/SimpleDropdown";
import {
    AnalyticsNetworkBuildWithAnalyticsIdsData,
    AnalyticsNetworkBuildWithAnalyticsIdsVariables,
    AnalyticsQueryType,
    AthenaDatasetResult,
    TempLink,
    TempLinkVariables
} from "../../../models/types";
import { loadCsv } from "../../../common/AnalyticsHelpers";
import { useLazyQuery } from "@apollo/client";
import {
    GET_ANALYTICS_NETWORK_BUILDS_WITH_ANALYTICS_IDS,
    GET_TEMP_LINK
} from "../../../graphql/queries";
import { OverlayBadge } from "../../simple/OverlayBadge";
import styles from "./AnalyticsBuildPreview.module.scss";

interface Props {
    data: AthenaDatasetResult[];
}

export const AnalyticsBuildPreview: FC<Props> = ({ data }) => {
    const [play, updatePlay] = useState(false);
    const [selection, setSelection] = useState("");
    const [isPortrait, updateIsPortrait] = useState(true);
    const [iFrameContent, setIFrameContent] = useState<string | undefined>(
        undefined
    );
    const [getAnalyticsNetworkBuilds, { data: analyticsBuilds }] = useLazyQuery<
        AnalyticsNetworkBuildWithAnalyticsIdsData,
        AnalyticsNetworkBuildWithAnalyticsIdsVariables
    >(GET_ANALYTICS_NETWORK_BUILDS_WITH_ANALYTICS_IDS);

    // @ts-ignore
    const [getTempLink, { data: { generateLink } = {} }] = useLazyQuery<
        TempLink,
        TempLinkVariables
    >(GET_TEMP_LINK, {
        fetchPolicy: "no-cache",
        refetchWritePolicy: "overwrite"
    });

    useEffect(() => {
        if (data.length > 0) {
            const buildIds: string[] = [];

            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                const funnelData = item.dataset?.find(
                    element =>
                        element.type ===
                        AnalyticsQueryType.NetworkBuildComparisonFunnel
                );
                if (funnelData?.success && funnelData.message) {
                    loadCsv(funnelData.message)
                        .then(result => {
                            for (let j = 0; j < result.length; j++) {
                                const element = result[j];

                                if (element.build_id) {
                                    buildIds.push(element.build_id);
                                }
                            }
                            if (i === data.length - 1 && buildIds.length > 0) {
                                getAnalyticsNetworkBuilds({
                                    variables: {
                                        analyticsBuildIds: buildIds
                                    }
                                });
                            }
                        })
                        .catch(error => console.log("[DEBUG] error ", error));
                }
            }
        }
    }, [data]);

    useEffect(() => {
        const currentBuild =
            analyticsBuilds?.analyticsNetworkBuildsWithAnalyticsIds?.find(
                networkBuild =>
                    `Build ${networkBuild.build?.id} ${networkBuild.network} ${networkBuild.variationName} ${networkBuild.variation} ${networkBuild.duration} ${networkBuild.size}MB` ===
                    selection
            );
        if (currentBuild && currentBuild.build) {
            getTempLink({
                variables: {
                    productId:
                        currentBuild?.build?.revision?.project?.product?.id ||
                        -1,
                    key: `${currentBuild.build.reportData.info.prefix}/${currentBuild.filename}`
                }
            });
        }
    }, [selection]);

    useEffect(() => {
        const fetchHtmlFromS3 = async (generateLink: string) => {
            try {
                const response = await fetch(generateLink);
                if (!response.ok) {
                    console.log(
                        "[DEBUG] Failed to fetch the HTML file from S3, no build yet or a failed build"
                    );
                    return;
                }

                const htmlData = await response.text();
                setIFrameContent(htmlData);
                updatePlay(true);
            } catch (error) {
                console.error("Error fetching the file:", error);
            }
        };

        if (generateLink && generateLink.link) {
            fetchHtmlFromS3(generateLink.link);
        }
    }, [generateLink]);

    const buildNames =
        analyticsBuilds?.analyticsNetworkBuildsWithAnalyticsIds?.map(
            networkBuild =>
                `Build ${networkBuild.build?.id} ${networkBuild.network} ${networkBuild.variationName} ${networkBuild.variation} ${networkBuild.duration} ${networkBuild.size}MB`
        );

    const datasetNames = data.map(dataset => dataset.datasetName).join(" & ");

    return (
        <Card className="text-inverse mt-4">
            <Card.Header className="d-flex justify-content-between align-items-center">
                {data.length > 1 && (
                    <h5>
                        <span className="text-inverse-75">
                            Playable previews from
                        </span>{" "}
                        {datasetNames}
                    </h5>
                )}
                {data.length === 1 && (
                    <h5 className="text-inverse-75">Playable Preview</h5>
                )}
                <OverlayBadge
                    badgeHeader="?"
                    badgeVariant="secondary"
                    className="ms-4"
                    overlayText="Select a build from the dropdown to play in the preview - list contains ads with valid analytics data from the query selection"
                ></OverlayBadge>
            </Card.Header>
            <Card.Body>
                {buildNames && buildNames.length > 0 ? (
                    <>
                        <div className="d-flex mb-3">
                            <SimpleDropdown
                                header={
                                    selection !== ""
                                        ? selection
                                        : "Select build"
                                }
                                choices={buildNames}
                                updateSelection={(selection: string) =>
                                    setSelection(selection)
                                }
                            />
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 500, hide: 100 }}
                                overlay={
                                    <Tooltip>
                                        {play
                                            ? "Stop playing"
                                            : "Start playing"}
                                    </Tooltip>
                                }
                            >
                                <Button
                                    onClick={() => updatePlay(!play)}
                                    disabled={!selection}
                                    className="ms-2"
                                >
                                    <FontAwesomeIcon
                                        icon={play ? faPause : faPlay}
                                    />
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 500, hide: 200 }}
                                overlay={
                                    <Tooltip>
                                        Toggle preview orientation - aspect
                                        ratio 16:9
                                    </Tooltip>
                                }
                            >
                                <Button
                                    onClick={() =>
                                        updateIsPortrait(!isPortrait)
                                    }
                                    className="ms-2"
                                >
                                    <FontAwesomeIcon icon={faRotate} />
                                </Button>
                            </OverlayTrigger>
                        </div>
                        {play ? (
                            <div
                                className={
                                    isPortrait
                                        ? styles.iframeContainer
                                        : styles.iframePlaceholderLandscape
                                }
                            >
                                <iframe
                                    width={isPortrait ? 300 : 533}
                                    height={isPortrait ? 533 : 300}
                                    title="Playable Preview"
                                    srcDoc={iFrameContent}
                                />
                            </div>
                        ) : (
                            <div
                                className={
                                    isPortrait
                                        ? styles.iframePlaceholder
                                        : styles.iframePlaceholderLandscape
                                }
                            >
                                <div className={styles.previewText}>
                                    Preview window
                                </div>
                            </div>
                        )}
                    </>
                ) : null}
            </Card.Body>
        </Card>
    );
};
