import {
    AnalyticsState,
    BuildsState,
    EditPanelState,
    NotificationState,
    PaginatorState,
    State
} from "./common";
import { ReactElement } from "react";
import { DSVRowArray } from "d3";

///////////
// State //
///////////
export interface StateData {
    state: State;
}

export interface AnalyticsStateData {
    analyticsState: AnalyticsState;
}

export interface BuildsStateData {
    buildsState: BuildsState;
}

export interface NotificationStateData {
    notificationState: NotificationState;
}

export interface PaginatorStateData {
    paginatorState: PaginatorState;
}

export interface EditPanelStateData {
    editPanelState: EditPanelState;
}

//////////
// User //
//////////
export type User = {
    id: number;
    displayName: string;
    email: string;
    useDarkMode: boolean;
    analyticsMode: boolean;
    organization?: Organization;
    products?: Product[];
    projects?: Project[];
    groupList: string[];
    scopes: string[];
    analyticsFollowList: string[];
    authType?: string;
};

export interface MeData {
    me: User;
}

export interface UserData {
    user: User;
}

export interface UsersData {
    users: User[];
}

export interface UpdateUserVariables {
    userId: number;
    organizationId: number;
}
export interface UpdateUserData {
    updateUserAccessBatch: User;
}

//////////////////
// Organization //
//////////////////
export interface CreateOrganizationData {
    createOrganization: Organization;
}

export interface UpdateOrganizationData {
    updateOrganization: Organization;
}

export enum OrganiazationType {
    Client,
    Subcontractor,
    Owner
}

export type Organization = {
    id: number;
    name: string;
    organizationType: OrganiazationType;
    organizationIdentifier?: string;
    dateUpdated: string;
};

export interface OrganizationData {
    organizations: Organization[];
}

export interface OrganizationVariables {
    organizationId?: number;
    name?: string;
    type?: number;
    organizationIdentifier?: string;
}

/////////////////////////
// Organization Config //
/////////////////////////
export type OrganizationConfig = {
    id: number;
    organization: Organization;
    softLimits: SoftLimitsData;
};

export interface OrganizationConfigInput {
    organizationId: number;
    softLimits?: SoftLimitsData;
}
export interface OrganizationConfigData {
    organizationConfig: OrganizationConfig;
}
export interface UpdateOrganizationConfigData {
    updateOrganizationConfig: OrganizationConfig;
}
export interface OrganizationConfigVariables {
    input: OrganizationConfigInput;
}

/////////////
// Product //
/////////////
export interface CreateProductData {
    createProduct: Product;
}

export interface ProductsData {
    products: Product[];
}

export interface ProductQueryVariables {
    searchTerm?: string;
}

export interface ProductsAdminData {
    allProducts: Product[];
}

export interface ProductMeta {
    development: number;
    review: number;
    accepted: number;
    rejected: number;
    released: number;
    bugged: number;
    testAvailable: number;
    testRequests: number;
}

export interface ProductMetaData {
    productMetaData: ProductMeta;
}

export type Product = {
    id: number;
    name: string;
    organization: Organization;
    projects?: Project[];
    dateUpdated: string;
    userImpressions?: number;
    userGameplay?: number;
    userEndScreen?: number;
    impressions?: number;
    gameplay?: number;
    endScreen?: number;
    selected?: boolean;
    comparison?: string;
};

export interface ProductVariables {
    id?: number;
    productId?: number;
    name?: string;
    organizationId?: number;
}

export interface AnalyticsProductData {
    analyticsProducts: Product[];
}

export interface AnalyticsProductVariables {
    organizationId: number;
}

/////////////
// Project //
/////////////
export enum ProjectEnvironment {
    Local,
    Development,
    Staging,
    Production
}

export interface ProjectData {
    project?: Project;
    revisions?: Revision[];
    builds?: BatchBuild[];
}

export interface CreateProjectData {
    createProject: Project;
}

export interface ProjectsData {
    projects: Project[];
}

export interface ProjectsAdminData {
    allProjects: Project[];
}

export enum ProjectState {
    Request,
    Offer,
    PreProduction,
    Review,
    Release
}

export type Project = {
    id: number;
    name: string;
    gitProjectId: number | undefined;
    gitProjectName: string;
    gitProjectBranch: string;
    slackChannel: string;
    state: ProjectState;
    revisions?: Revision[];
    product?: Product;
    dateUpdated: string;
    userImpressions?: number;
    userGameplay?: number;
    userEndScreen?: number;
    impressions?: number;
    gameplay?: number;
    endScreen?: number;
    selected?: boolean;
    comparison?: string;
};

export interface ProjectSettings {
    projectId: number;
    name: string;
    slackChannel: string;
    gitProjectName: string;
    gitProjectBranch: string;
    gitProjectId?: number;
}

export interface ProjectVariables {
    id?: number;
    projectId?: number;
    name?: string;
    productId?: number;
    gitProjectId?: number | undefined;
    gitProjectName?: string | undefined;
    gitProjectBranch?: string | undefined;
    slackChannel?: string | undefined;
    searchTerm?: string;
}

export interface UpdateProjectData {
    updateProject: Project;
}

export interface ProjectMeta {
    development: number;
    review: number;
    accepted: number;
    rejected: number;
    released: number;
    bugged: number;
    testAvailable: number;
    testRequests: number;
}

export interface ProjectMetaData {
    projectMetaData: ProjectMeta;
}

export interface AnalyticsProjectData {
    analyticsProjects: Project[];
}

export interface AnalyticsProjectVariables {
    productId: number;
}

////////////////////
// Project Config //
////////////////////
export type ProjectConfig = {
    id: number;
    project: Project;
    softLimits: SoftLimitsData;
    analyticsNetworks: string[];
    zipNameTemplate: string;
};

export interface ProjectConfigInput {
    projectId: number;
    softLimits?: SoftLimitsData | null;
    analyticsNetworks?: string[] | null;
    zipNameTemplate?: string | null;
}
export interface ProjectConfigData {
    projectConfig: ProjectConfig;
}
export interface UpdateProjectConfigData {
    updateProjectConfig: ProjectConfig;
}
export interface ProjectConfigVariables {
    input: ProjectConfigInput;
}

////////////////////////
// Create New Project //
////////////////////////
export interface CreateNewProjectData {
    createDeveloperProject: {
        project: Project;
        taskId?: string;
    };
}

export interface CreateProjectConfig {
    keepBaseSets: boolean;
    name: string;
    fullName: string;
    description: string;
    genre: string;
    gitSlug: string;
    productId?: number;
    gitModules: Submodule[];
    baseProject: BaseProject | undefined;
    slackChannel?: string | undefined;
    techStack?: TechStackConfig;
}

export interface NewProjectConfigVariables {
    configKey: string;
    configValue: string;
}

export interface DeveloperProjectVariables {
    config: string;
}

export interface TechStack {
    name: string;
    package?: string;
    version?: string;
    blocking?: string[];
}

export interface TechStackData {
    allowMultiple: boolean;
    selection: TechStack[];
}

export type TechStackConfig = {
    frameworks: TechStackData;
    sounds: TechStackData;
    particles: TechStackData;
    physics: TechStackData;
    animations: TechStackData;
    networks: TechStackData;
};

export interface Submodule {
    name: string;
    description: string;
    path: string;
    branch: string;
    url: string;
}

export interface NewProjectConfigData {
    getCreateProjectConfig: NewProjectConfig;
}

export interface NewProjectConfig {
    gitModules: BaseModule[];
    baseProjects: BaseProject[];
    techStack: TechStackConfig | undefined;
}

export enum CreateProjectState {
    Organization,
    Product,
    Project,
    Valid,
    Start,
    Pipeline,
    Completed
}

export interface RequiredSubmodule {
    path: string;
    branch: string;
}

export interface BaseProject {
    name: string;
    description: string;
    branch: string;
    url: string;
    requiredSubmodules: RequiredSubmodule[];
    allowTemplator: boolean;
    experimental?: boolean;
    legacy?: boolean;
}

export interface BaseModule {
    name: string;
    description: string;
    path: string;
    branch: string;
    url: string;
    required: boolean;
}

export interface GitlabSlugResultData {
    getGitlabSlugStatus: BasicResult;
}

export interface GitlabSlugVariables {
    slug: string;
}

//////////////
// Revision //
//////////////
export enum ReleaseType {
    Development,
    Review,
    ReviewRejected,
    ReviewAccepted,
    Release,
    ClientRelease
}

export const ReleaseTypeVariant: string[] = [
    "info",
    "review",
    "danger",
    "success",
    "primary",
    "secondary"
];

export type Revision = {
    id: number;
    project: Project;
    name: string;
    gitId?: number;
    branch?: string;
    releaseType: ReleaseType;
    revisionConfig?: RevisionConfig;
    archived: boolean;
    buildData?: any;
    analyticsConfig?: AnalyticsSchema;
    dateCreated?: string;
    dateUpdated: string;
    userImpressions?: number;
    userGameplay?: number;
    userEndScreen?: number;
    impressions?: number;
    gameplay?: number;
    endScreen?: number;
    selected?: boolean;
    comparison?: string;
};

export interface RevisionVariables {
    revisionId?: number;
    project?: number;
    branch?: string;
    commit?: string;
    buildData?: string;
    variables?: string;
}

export interface ReleaseVariables {
    projectId: number;
}

export interface PartialRevisionVariables {
    revisionId: number;
    partialData: Types;
    operation?: string;
}

export interface PartialRevisionData {
    partialUpdateRevision: {
        version: number;
        newId?: number;
        operation: string;
    };
}
export interface RevisionData {
    revision: Revision;
}

export interface ReleaseData {
    release: Revision;
}

export interface DevelopmentRevisionData {
    developmentRevision: Revision;
}

export interface RevisionSubscriptionData {
    revisionNotification: Revision;
}

export interface RevisionConfigData {
    revisionConfig: RevisionConfig;
}

export interface RevisionHistoryData {
    revisionEvents: RevisionHistoryItem[];
}

export interface RevisionsData {
    revisions?: Revision[];
}

export interface RevisionsVariables {
    projectId: number;
    searchTerm?: string;
}

export interface RevisionsByProductVariables {
    productId: number;
}

export interface CopyRevisionData {
    copyRevision: Revision;
}

export interface CopyRevisionVariables {
    projectId: number;
    revisionId: number;
}

export interface CreateAdsetData {
    createAdset: Revision;
}

export interface AdsetVariables {
    projectId?: number;
    revisionId: number;
}

export interface ReleaseNotesData {
    getReleaseNotes: { releaseNotes: string };
}

export interface EditPanelValidation {
    valid: boolean;
    message?: string;
}

export interface AnalyticsRevisionData {
    analyticsRevisions: Revision[];
}

export interface AnalyticsRevisionVariables {
    projectId: number;
}

/////////////////////////
// Revision Variations //
/////////////////////////
export interface TempBuildsData {
    taskId?: string;
}

export interface TempBuildVariables {
    revisionId: number;
    targetNetwork?: string;
}

export type Build = {
    id: number;
    name: string;
    sets: string[];
    duration: string;
    path: string;
    description: string;
    size: number;
    include: boolean;
};

export type Types = {
    builds?: Build[];
    variations?: Variation[];
    localizations?: Localization[];
    legacyLocalizations?: LegacyLocalization[];
    analytics?: boolean;
    networks?: Network[];
    placeholders?: string[];
    nameTemplate?: string;
    generateStats?: boolean;
    generateReport?: boolean;
    softLimits?: SoftLimitsData;
};

export interface VariationSet {
    name: string;
    set: string;
}

export type Variation = {
    id: number;
    name: string;
    description: string;
    sets: VariationSet[];
    include: boolean;
};

export type LegacyLocalization = {
    id: number;
    name: string;
    localization: string;
    include: boolean;
};

export type Localization = {
    id: number;
    name: string;
    localization: string;
    include: boolean;
    default?: boolean;
    description?: string;
};

export type Network = {
    id: number;
    network: string;
    sizeTargets: number[];
    analytics: boolean;
};

export interface LocalizationData {
    localizationKey: string;
    localizationJson: string;
}

export interface LocalizationQueryData {
    getLocalization: LocalizationData;
}

export interface LocalizationDataVariables {
    revisionConfigId: number;
    localization: string;
}

export interface GenerateLocalizationData {
    getLocalizationsJson: LocalizationData;
}

export interface GenerateLocalizationVariables {
    revisionId: number;
    localizationData: string;
}

export interface UploadLocalizationVariables {
    revisionId: number;
    localization?: string;
    localizationFileName?: string;
    localizationJson?: string;
}

export interface SoftLimitsData {
    global: number[];
    [key: string]: number[];
}

export enum SoftLimitState {
    None,
    Organization,
    Project,
    Revision
}

/////////////////////////////////
// Revision Variation Settings //
/////////////////////////////////
export type VariationSettings = {
    debug: boolean;
    generateReport: boolean;
    generateStats: boolean;
    namingScheme: string;
    nopixi: boolean;
    pathScheme: string;
    forceUppercasePath: boolean;
    useDurationNames: boolean;
    useLegacyLocalizations: boolean;
    useLegacyNetworks: boolean;
    gzip: boolean;
    useAditor: boolean;
    useMutator: boolean;
};

export interface ExampleNameData {
    getExampleName: string;
}

export interface ExampleNameVariables {
    nameTemplate: string;
    revisionId: number;
}

export interface ExampleCommandData {
    getExampleCommand: BuildJobData;
}

/////////////////////
// Revision Config //
/////////////////////
export interface UploadLocalizationData {
    addLocalization: {
        version: number;
        partialData: string;
        revisionConfig: RevisionConfig;
    };
}

export interface RevisionConfig {
    id: number;
    configurator?: any;
    templatorConfig?: any;
    sets?: string[];
    setBlacklist?: string[];
    setPlaceholder?: string[];
    localizations?: string[];
    useTemplatorInPipeline: boolean;
    allowTemplatorConfigModification: boolean;
}

export interface RevisionConfigVariables {
    id?: number;
    revisionId?: number;
}

export interface RevisionConfigInputVariables {
    input: RevisionConfigInput;
}

export interface LocalizationTagValidationData {
    isValidLocalizationTag: boolean;
}
export interface LocalizationTagValidationVariables {
    localizationTag: string;
}

////////////////////
// Revision Event //
////////////////////
export enum FeedbackType {
    Accept = 4,
    Reject = 5,
    TestFeedback = 8,
    RequestTestBuild = 12
}

export enum EventType {
    Created,
    Updated,
    Promoted,
    Demoted,
    Accepted,
    Rejected,
    Released,
    Attached,
    Feedback,
    TagBuildAsReview,
    TagBuildAsTest,
    UntagBuild,
    RequestTestBuild,
    RequestTestBuildFulfilled
}

export const EventTypeVariant: string[] = [
    "analytics-alt",
    "secondary",
    "primary",
    "demoted",
    "success",
    "danger",
    "info",
    "dark",
    "test",
    "review",
    "test",
    "rejected",
    "warning",
    "info-2"
];
export interface RevisionEventData {
    createRevision: RevisionEvent;
}

export interface RevisionEvent {
    id: number;
    eventType: number;
    description?: string;
}

export interface RevisionEventVariables {
    revisionId: number;
    eventType: FeedbackType;
    description?: string;
    files?: any;
}

export interface RevisionHistoryItem {
    id: number;
    user?: User;
    eventType: EventType;
    build?: BatchBuild;
    description?: string;
    keys?: string[];
    dateCreated: string;
}

/////////////////
// Batch Build //
/////////////////

export type BatchBuild = {
    id: number;
    revision: Revision;
    state: BatchBuildState;
    jobIds?: string[];
    reportData: ReportData | ReportDataV2;
    analyticsSchema?: AnalyticsSchema;
    dateCreated: string;
    dateUpdated: string;
    impressions?: number;
    gameplay?: number;
    endScreen?: number;
    pathTemplate?: string;
    zipUrl?: string;
    legacyLinkUrl?: string;
    buildJobCount: number;
    networkBuilds: NetworkBuild[];
    analyticsEnabled: boolean;
    legacyLocalizations: boolean;
    buildType: BuildType;
    selected?: boolean;
    comparison?: string;
};

export enum BatchBuildState {
    Started,
    JobCreated,
    JobStarted,
    JobBuilt,
    JobCleaned,
    Completed,
    Review,
    Failed,
    Test,
    Stopped,
    Tested
}

export interface BatchBuildData {
    build: BatchBuild;
}

export interface BatchBuildVariables {
    id: number;
}

export type BuildJobData = {
    name: string;
    command: string;
    include: boolean;
};

export type PipelineLogs = {
    startJob: string;
    notifyJob: string;
    cleanJob: string;
    buildJobs: string[];
};

export interface PipelineLogsData {
    getPipelineLogs: PipelineLogs;
}

export interface PipelineBuildLogsData {
    getBuildPipelineLogs: PipelineLogs;
}

export interface PipelineLogsVariables {
    buildId: number;
}

export interface CreateBatchBuildData {
    createBuild: BatchBuild;
}

export interface CancelBatchBuildData {
    cancelBuild: { build: BatchBuild };
}

export interface CancelBatchBuildVariables {
    buildId: number;
}

export interface LegacyPageData {
    generateLinkPage: BasicResult;
}

export interface TempLinkVariables {
    productId: number;
    key: string;
    localization?: string;
}

export interface ShareLinkVariables {
    buildId: number;
    fileName?: string;
}

export interface BatchBuildsData {
    builds: BatchBuild[];
}

export interface BatchBuildsVariables {
    projectId?: number;
    revisionId?: number | string;
}

export enum BuildPipelineEventStatus {
    InQueue,
    InProgress,
    Completed,
    Failed
}

export interface LogType {
    duration: number;
    event: string;
    id: number;
    log: string;
    type: number;
}

export interface LogCard {
    card: JSX.Element;
    warnings: number;
    errors: number;
}

export type ReportData = {
    info: ReportBasicData;
    builds?: NetworkBuildReportData[];
    duplicates?: string[];
    warnings?: ReportWarnings;
    pipelineErrors?: PipelineError[];
    localizations?: string[];
    zip?: string;
    legacyLink?: string;
};

export type ReportDataV2 = {
    info: ReportBasicData;
    builds?: NetworkBuildReportDataV2[];
    buildErrors?: number[];
    buildWarnings?: number[];
    pipelineErrors?: PipelineErrors[];
    pipelineWarnings?: PipelineWarnings[];
    duplicates?: string[];
    localizations?: string[];
    zip?: string;
    legacyLink?: string;
    version: number;
};

export type PipelineError = {
    job: number;
    type: string;
    message: string;
};

export type PipelineWarnings = {
    job: string;
    ids: number[];
};

export type PipelineErrors = {
    job: string;
    ids: number[];
};

export type ReportBasicData = {
    bucket: string;
    prefix: string;
    networks: string[];
    localizations: string[];
    builds: string[];
    variations: string[];
    sizes: string[];
    durations: string[];
    sets: string[];
};

export type ReportError = {
    duplicates: string[];
};

export type ReportWarnings = {
    buildWarnings: string[];
};

export enum BuildType {
    Normal,
    Mutator
}

export interface AnalyticsBatchBuildData {
    analyticsBuilds: BatchBuild[];
}

export interface AnalyticsBatchBuildVariables {
    revisionId: number;
}

///////////////////
// Network Build //
///////////////////
export enum NetworkBuildState {
    Create,
    Start,
    Build,
    Clean,
    Completed,
    Failed
}

export type NetworkBuild = {
    id: number;
    build?: BatchBuild;
    eventId?: string;
    state: NetworkBuildState;
    arrayIndex: number;
    filename?: string;
    size?: number;
    variationName?: string;
    command?: string;
    network: string;
    byteSize?: number;
    duration?: string;
    fileHash?: string;
    buildName: string;
    variation?: string;
    buildIndex?: string;
    customPath?: string;
    excludedSets?: string[];
    includedSets: string[];
    localization?: string;
    errors: number[];
    warnings: number[];
    buildToolWarnings: string[];
    statsFile?: string;
    analyticsEnabled: boolean;
    analyticsBatchId: string;
    analyticsBuildId: string;
    analyticsVersion: string;
    networkTest?: NetworkTest[];
    userQueryBuildAnalytic?: DailyBuildAnalytics[];
    dailyBuildAnalytic?: DailyBuildAnalytics[];
    selected?: boolean;
    comparison?: string;
};

export type NetworkBuildReportData = {
    id: string;
    file?: string;
    size?: number;
    command?: string;
    network: string;
    duration?: string;
    build?: string;
    variation?: string;
    byteSize?: number;
    fileHash?: string;
    buildName: string;
    extension?: string;
    statsFile?: string;
    customPath?: string;
    localization?: string;
    excludedSets?: string[];
    includedSets: string[];
    buildWarnings: string[];
    analytics: BuildAnalyticsConfig;
    impressions?: number;
    gameplay?: number;
    endScreen?: number;
};

export type NetworkBuildReportDataV2 = {
    id: string;
    file?: string;
    size?: number;
    command?: string;
    network: string;
    duration?: string;
    build?: string;
    variation?: string;
    byteSize?: number;
    fileHash?: string;
    buildName: string;
    extension?: string;
    statsFile?: string;
    customPath?: string;
    localization?: string;
    excludedSets?: string[];
    includedSets: string[];
    warnings: number[];
    errors: number[];
    analytics: BuildAnalyticsConfig;
    impressions?: number;
    gameplay?: number;
    endScreen?: number;
};

export type BuildAnalyticsConfig = {
    buildId: string;
    batchId: string;
    version: string;
};

export interface ReadyNotificationData {
    readyNotification: {
        success: boolean;
        type: string;
        link: string;
    };
}

export interface AnalyticsNetworkBuildData {
    analyticsNetworkBuilds: NetworkBuild[];
}

export interface AnalyticsNetworkBuildVariables {
    buildId: number;
}

export interface AnalyticsNetworkBuildWithAnalyticsIdsData {
    analyticsNetworkBuildsWithAnalyticsIds: NetworkBuild[];
}

export interface AnalyticsNetworkBuildWithAnalyticsIdsVariables {
    analyticsBuildIds: string[];
}
///////////////
// Analytics //
///////////////
export type EventData = {
    key: string;
    value: string;
};

export type AnalyticsBuild = {
    id: number;
    buildId: string;
    batchId: string;
    network: string;
    tag: string;
    filename: string;
    apiVersion: string;
};

export type AnalyticsBuildData = {
    analyticsBuild: AnalyticsBuild;
};

export interface AnalyticsQueryResult {
    getAnalyticsByQuery: {
        success: boolean;
        message: string;
    };
}

export interface DaySelectionFunnelResults {
    labels: string[];
    dates: string[];
    events: number[][];
}

export interface AnalyticsTaskResult {
    generateAnalyticsTask: {
        taskId?: string;
        subTasks?: AnalyticsSubTasksState;
    };
}

export interface AnalyticsSubTasksState {
    subTaskCount: number;
    completedCount: number;
}

export interface AnalyticsBaseQueryResult {
    success: boolean;
    builds: AnalyticsBuild[];
    message: string;
    key: string;
    taskId?: string;
}

export interface FunnelQueryResult {
    success: boolean;
    builds: AnalyticsBuild[];
    message: string;
    key?: string;
    heatmapResult?: AnalyticsBaseQueryResult;
}

export interface AnalyticsQueryResultWithBuilds {
    getAnalyticsQueryResultWithIds: AnalyticsBaseQueryResult;
}

export interface FunnelAnalyticsQueryResultWithBuilds {
    getFunnelAnalyticsByQuery: FunnelQueryResult;
}

export interface HeatmapAnalyticsQueryResultWithBuilds {
    getAnalyticsHeatmap: AnalyticsBaseQueryResult;
}

export interface AnalyticsEnabledBuildsResult {
    getAnalyticsEnabledBuilds: BatchBuild[];
}

export interface LegacyAnalyticsEnabledBuildsSetResult {
    getLegacyAnalyticsEnabledBuildSets: LegacyAnalyticsBuildSet[];
}

export interface AnalyticsQueryResultWithConfig {
    getAnalyticsByQuery: {
        success: boolean;
        message: string[];
    };
}

export interface AnalyticsQueryVariables {
    input: AnalyticsQueryConfig;
}

export interface AnalyticsBatchBuildQueryVariables {
    buildId: string;
}

export interface AnalyticsEnabledBuild {
    id: number;
    analyticsSchema: AnalyticsSchema;
    revision: Revision;
}

export interface AnalyticsBatchBuildQueryData {
    analyticsEnabledBuild: AnalyticsEnabledBuild;
}

export interface AnalyticsLegacyBatchBuildQueryData {
    legacyBuildSet: LegacyAnalyticsBuildSet;
}

export interface AnalyticsTaskVariables {
    taskId: string;
}

export interface AnalyticsQueryVariablesWithConfig {
    config: string;
}

export interface SavedAnalyticsQueriesResult {
    getUserAnalyticsQueries: AnalyticsQuery[];
}

export enum AnalyticsLoadingState {
    Ready,
    QueryingAthena,
    LoadingCsv,
    LoadingCharts,
    Error
}

export interface AnalyticsTag {
    name: string;
    id: number;
}

export interface AnalyticsConfig {
    organizations: AnalyticsTag[];
    products: AnalyticsTag[];
    projects: AnalyticsTag[];
    revisions: AnalyticsTag[];
    apiVersion: string;
    dateString: string;
}

export interface AnalyticsQueryConfig {
    apiVersion: string;
    eventList: FunnelData[];
    dates: AnalyticsTimestamps;
    includeDayFunnel: boolean;
    includeBuildPreview: boolean;
    includeBuildComparison: boolean;
    querySets: AnalyticsQuerySet[];
}

export interface AnalyticsQuerySetSelection {
    products: number[];
    projects: number[];
    revisions: number[];
    builds: number[];
    networkBuilds: number[];
}

export interface AnalyticsQuerySet {
    selection: AnalyticsQuerySetSelection;
    exclusion: AnalyticsQuerySetSelection;
}

export type FunnelData = {
    eventName: string;
    eventData: EventData[];
    exclude?: boolean;
};

export enum AnalyticsQueryType {
    AllCtr,
    BatchBuildCtr,
    Funnel,
    DaySelectionFunnel,
    TimeAverages,
    TimeDistribution,
    HeatmapCombined,
    HeatmapPortrait,
    HeatmapLandscape,
    FunnelDaySelectionExtraCalculation,
    CtrExtraCalculation,
    NetworkBuildComparisonFunnel
}

export type AnalyticsQuery = {
    id: number;
    name: string;
    description?: string;
    type: AnalyticsQueryType;
    config: string;
    queryTask: AnalyticsQueryTask | null;
    dateCreated: string;
};

export interface SaveAnalyticsQueryVariables {
    name: string;
    description?: string;
    type: AnalyticsQueryType;
    config: string;
    queryTaskId?: number;
}

export interface AnalyticsQueryData {
    type: AnalyticsQueryType;
    key: string;
    dates?: AnalyticsTimestamps;
}

export interface SavedAnalyticsQueryConfig {
    apiVersion: string;
    timeRange: AnalyticsTimestamps;
    selectedBuilds: string[];
    funnelItems: FunnelData[];
    queries: AnalyticsQueryData[];
    builds: AnalyticsBuild[];
    batchBuildSelection: ReleaseType[];
}

export interface SaveAnalyticsQueryResult {
    createAnalyticsQuery: AnalyticsQuery;
}

export interface UpdateAnalyticsLibEventResult {
    updateAnalyticsLibEvent: AnalyticsEventSchema;
}

export interface AnalyticsTimestamps {
    startTimestamp: number;
    endTimestamp: number;
}

export interface AnalyticsEventByOrgVariables {
    organizationId: number;
}

export interface AnalyticsEventVariables {
    eventId?: number;
    eventName?: string;
    eventData?: string;
    comment?: string;
    frozen?: boolean;
}

export type AnalyticsEventSchema = {
    id: number;
    eventName: string;
    eventData: AnalyticsEventDataSchema[];
    comment: string;
    frozen: boolean;
    organization?: Organization;
};

export type AnalyticsEventDataSchema = {
    key: string;
    values: string[] | number[];
};

export type AnalyticsSchema = {
    events: AnalyticsEventSchema[];
    description?: string;
};

export type AnalyticsEventsById = {
    getEventsByOrgId: AnalyticsEventSchema[];
};

export type AnalyticsSchemaData = {
    getAnalyticsSchema: {
        success: boolean;
        schema: string;
        isComplete: boolean;
    };
};

export type getAnalyticsEvents = {
    getAnalyticsEvents: AnalyticsEventSchema[];
};

export type AnalyticsSchemaByIdData = {
    getAnalyticsSchemaById: { success: boolean; schema: string };
};

export type AnalyticsSchemaVariables = {
    buildId?: number;
    analyticsProducts?: number[];
    analyticsProjects?: number[];
    analyticsRevisions?: number[];
    analyticsBuilds?: number[];
    analyticsNetworkBuilds?: number[];
    apiVersion?: string;
};

export interface DailyAnalytics {
    id: number;
    product?: Product;
    project?: Project;
    revision?: Revision;
    build?: BatchBuild;
    impressions: number;
    gameplay: number;
    endScreen: number;
}

export interface DailyBuildAnalytics {
    id: number;
    adBuildId: string;
    impressions: number;
    gameplay: number;
    endScreen: number;
}

export type DailyAnalyticData = {
    getDailyAnalytic: DailyAnalytics;
};

export type DailyBuildAnalyticsData = {
    getDailyBuildAnalytics: DailyBuildAnalytics[];
};

export type DailyAnalyticsData = {
    getDailyAnalytics: DailyAnalytics[];
};

export type UserQueryAnalyticsData = {
    getUserQueryAnalytic: DailyAnalytics;
};

export type UserQueryAnalyticsBuildData = {
    getUserQueryBuildAnalytic: DailyBuildAnalytics;
};

export type DailyAnalyticsBuildData = {
    getDailyBuildAnalytic: DailyBuildAnalytics;
};

export type DailyAnalyticVariables = {
    referenceId: number;
    reference: string;
};

export type DailyAnalyticBuildVariables = {
    id: number;
};

export type DailyAnalyticsVariables = {
    reference: string;
};

export type AnalyticsBuildInfoVariables = {
    buildId: string;
    apiVersion?: string;
};

// export interface AnalyticsQueryConfig {
//     type: AnalyticsQueryType;
//     dates: AnalyticsTimestamps;
//     apiVersion?: string;
// }

export interface AthenaTaskQueryResult {
    taskId: string;
    queryType: AnalyticsQueryType;
    results: AthenaQueryResult[];
    success: boolean;
    allComplete?: boolean;
}

export interface AthenaDatasetResult {
    datasetName: string;
    dataset: AthenaQueryResult[];
}

export interface AthenaQueryResult {
    success: boolean;
    type: AnalyticsQueryType;
    message?: string;
    key?: string;
    dates?: AnalyticsTimestamps;
    taskId?: string;
    completedCount?: number;
}

export enum AnalyticsQueryTaskFetchState {
    Querying,
    QueryCompleted,
    FetchStarted,
    FetchCompleted
}

export interface AnalyticsQueryTask {
    taskId: string;
    type: AnalyticsQueryType;
    dates?: AnalyticsTimestamps;
    result?: DaySelectionFunnelResults;
    isReady: boolean;
    includeDayFunnel?: boolean;
    fetchStatus: AnalyticsQueryTaskFetchState;
    subQueries?: AnalyticsSubTasksState;
}

export enum QueryState {
    Ready,
    Querying,
    Processing
}

export enum HeatmapType {
    Combined,
    Portrait,
    Landscape
}

export type HeatmapData = {
    data: DSVRowArray;
    type: AnalyticsQueryType;
};

export interface FunnelEnabledData {
    funnelEnabled: boolean;
}

export interface FunnelEnabledVariables {
    productId?: number | string;
    projectId?: number | string;
    revisionId?: number | string;
    buildId?: number | string;
}

export interface FunnelEnabledData {
    funnelEnabled: boolean;
}

export interface FunnelEnabledVariables {
    productId?: number | string;
    projectId?: number | string;
    revisionId?: number | string;
    buildId?: number | string;
}

export enum DailyAnalyticsType {
    LastDay,
    LastWeek,
    LastMonth,
    LastQuarter,
    LastHalf,
    LastYear,
    LastCalendarWeek,
    LastCalendarMonth,
    LastCalendarQuarter,
    LastCalendarHalf,
    LastCalendarYear
}

export interface DailyAnalyticsListElement extends DailyAnalytics {
    allGameplay?: number;
    allEndScreen?: number;
    allImpressions?: number;
    ctr?: number;
}

export interface AnalyticsDailyListData {
    getDailyAnalyticsList: DailyAnalyticsListElement[];
}

export interface ComparisonData {
    batch_id: string;
    build_id: string;
    adStart: number;
    ctaBtnPressed: number;
    CTR: number;
}

//////////////////////
// Legacy Analytics //
//////////////////////
export type LegacyAnalyticsBuildSet = {
    id: number;
    projectName: string;
    gitProject: string;
    gitBranch: string;
    gitCommit: string;
    batchId: string;
    version: string;
    networks: string[];
    builds?: AnalyticsBuild[];
};

//////////////////
// File Uploads //
//////////////////
export interface CreateFileUploadsData {
    createFileUploads: FileUploads;
}

export interface FileUploadsVariables {
    id?: number;
    productId?: number;
    name?: string;
    key?: string;
}

export type FileUpload = {
    id: number;
    name: string;
    key: string;
};

export interface FileUploads {
    fileUploads: FileUpload[];
}

//////////////////
// Network Test //
//////////////////
export enum NetworkTestState {
    Started,
    JobCreated,
    JobStarted,
    Completed,
    Failed,
    Stopped
}

export type NetworkTest = {
    id: number;
    network: string;
    state: NetworkTestState;
    isAutomatic: boolean;
    report?: string;
    dateUpdated?: string;
};

export interface NetworkTestVariables {
    networkBuildId: number;
}

export interface NetworkTestData {
    networkTest: NetworkTest;
}

export interface CancelNetworkTestData {
    cancelNetworkkTest: { networkTest: NetworkTest };
}

export interface CancelNetworkTestVariables {
    networkTestId: number;
}

////////////////////////
// Search and Sorting //
////////////////////////
export type SearchData = {
    networks: string[];
    localizations: string[];
    builds: string[];
    variations: string[];
    sizes: string[];
    durations: string[];
    sets: string[];
};

export enum SortingMode {
    None,
    NameAscending,
    NameDescending,
    DateAscending,
    DateDescending,
    CtrAscending,
    CtrDescending,
    UserCtrAscending,
    UserCtrDescending,
    ImpressionsAscending,
    ImpressionsDescending,
    UserImpressionsAscending,
    UserImpressionsDescending
}

export interface SortingData {
    sortingPriorities: SortingMode[];
    organizationSorting: boolean;
}

export enum OrderMode {
    None,
    Ascending,
    Descending
}

export enum OrderBy {
    DailyBuildImpressions = "daily_build_analytic__impressions",
    DailyBuildCtr = "daily_build_analytic__ctr",
    UserQueryImpressions = "user_query_build_analytic__impressions",
    UserQueryCtr = "user_query_build_analytic__ctr",
    NetworkTest = "network_test__id"
}

export type IconClickHandlerType = (
    newMode: SortingMode
) => React.MouseEventHandler<SVGSVGElement>;

export type ExpandedList = {
    [key: string]: boolean;
};

///////////////
// Messaging //
///////////////
export interface BasicVariables {
    id: number;
}

export interface BasicResult {
    success: boolean;
    message: string;
    state?: string;
}

///////////////////
// Notifications //
///////////////////
export type BuildIdVariable = {
    id: number;
};

export enum NotificationType {
    Info,
    Success,
    Danger
}

export interface ToastData {
    success: boolean;
    header: string;
    message: string;
    delay: number;
}

export interface UserNotification {
    id: number;
    title: string;
    message?: string | ReactElement;
    isNew: boolean;
    dateCreated?: any;
    dateUpdated?: any;
}

export interface BuildNotification {
    id: number;
    title: string;
    message?: string | ReactElement;
    path: string;
    isNew: boolean;
    isLink: boolean;
    type: NotificationType;
}

export interface UserNotificationData {
    userNotification: UserNotification;
}

export interface UserNotificationQueryData {
    getNotifications: UserNotification[];
}

export interface UserNotificationVariables {
    userId?: number;
}

export interface UserNotificationUpdateVariables {
    notificationId: number;
    isNew?: boolean;
}

export interface UserNotificationRemoveData {
    removeNotification: boolean;
}

export interface RevisionNotificationData {
    userNotification: UserNotification;
}

///////////
// Links //
///////////
export interface TempLink {
    generateLink: BaseLink;
}

export interface QueryLoadLink {
    generateLink: { link: string; key: string };
}

export interface DownloadLinkData {
    generateDownloadLink: BaseLink;
}

export interface BaseLink {
    link: string;
}

export interface ShareLink {
    generatePublicLink: BaseLink;
}

/////////
// CLI //
/////////
export interface GenerateDevApiKeyData {
    generateDevApiKey: {
        key: string;
        result: number;
    };
}

export interface GenerateDevApiKeyVariables {
    removeOld: boolean;
}

export interface DevApiKeyStatusData {
    getDevApiKeyStatus: {
        status: number;
    };
}

//////////
// Misc //
//////////
export interface DropdownAction {
    header: string | ReactElement;
    variant?: string;
    className?: string;
    callback: () => void;
}

////////////
// Inputs //
////////////
export type RevisionConfigInput = {
    id?: number;
    revisionId?: number;
    configurator?: any; // TODO: create types for configurator
    sets?: string[];
    setBlacklist?: string[];
    localizations?: string[];
    useTemplatorInPipeline?: boolean;
    allowTemplatorConfigModification?: boolean;
};

//////////////////
// Configurator //
//////////////////
export type ConfiguratorElementData = {
    sets: string[];
    value: string;
};

export type ConfiguratorData = {
    [key: string]: ConfiguratorElementData[];
};

/////////////
// Mutator //
/////////////
export type MutatorConfig = {
    revision: Revision;
    mutatorConfig: Mutator;
    mutatorKeyPrefix: string;
};

export interface AssetBundle {
    name: string;
    path: string;
    url: string;
}

export interface MutatorVariables {
    revisionId: number;
    mutatorConfig?: Mutator;
}

export interface MutatorUpdateVariables {
    input: MutatorUpdateInput;
}

export type MutatorUpdateInput = {
    revisionId: number;
    mutatorConfig: Mutator;
};

export interface MutatorQueryData {
    mutatorConfig: MutatorConfig;
}

export interface MutatorAssetbundlesQueryData {
    mutatorAssetbundles: AssetBundle[];
}

export interface MutatorUpdateData {
    mutatorUpdateConfig: MutatorConfig;
}

export interface MutatorCreateData {
    mutatorCreateConfig: MutatorConfig;
}

export interface MutatorRemoveData {
    mutatorRemoveConfig: boolean;
}

export interface MutatorProperty {
    name: string;
    type: string;
    value?: any;
    meta?: MutatorPropertyMeta;
}

export interface MutatorPropertyMeta {
    name?: string;
    type?: string;
    path?: string;
    description?: string;
    min?: number;
    max?: number;
    optional?: boolean;
    scopes?: MutatorPropertyMetaScopes;
}

export interface MutatorPropertyMetaScopes {
    read: string;
    create: string;
    delete: string;
    update: string;
}

export interface Mutator {
    version: number;
    properties: MutatorProperty[];
    enums: MutatorEnums;
    types: MutatorTypes;
}

export interface MutatorTypes {
    [key: string]: {
        properties: MutatorProperty[];
    };
}

// export interface MutatorType {
//     name: string;
//     type: string;
// }

export interface MutatorEnums {
    [key: string]: MutatorEnum;
}

export interface MutatorEnum {
    type: string;
    options: MutatorOption[];
}

export type MutatorOption = {
    name: string;
    value: any;
};

/////////////////
// Invitations //
/////////////////
export type UserInvitationVariables = {
    email: string;
    organizationId: number;
};

export type UserInvitationsVariables = {
    pending: boolean;
};

export type UserInvitation = {
    id?: number;
    email: string;
    token: string;
    url: string;
    dateExpired: string;
    user: User;
};

export type UserInvitationData = {
    userInvitation: UserInvitation;
};

export type UserInvitationsData = {
    getInvitations: UserInvitation[];
};

/////////////////////
// Platform Config //
/////////////////////
export interface PlatformConfig {
    id: number;
    useBuildRunner: boolean;
    supportedNetworks: string[];
    buildRunnerNetworks: string[];
    analyticsNetworks: string[];
    qrNetworks: string[];
    networkTestingNetworks: string[];
    networkTestingMinimumState: ReleaseType;
    analyticsApiVersion: string;
}

export interface PlatformConfigData {
    getPlatformConfig: PlatformConfig;
}
