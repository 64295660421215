import React from "react";

export const SlackInfoMessage = () => {
    return (
        <span className="small-font text-inverse">
            <p className="m-0 pb-1">
                By default slack messages are sent to{" "}
                <span className="font-weight-bold">platform-notifications</span>{" "}
                channel.
            </p>
            <p className="m-0 pb-1">
                You can additionally send a message to project's own slack
                channel by adding it as the slack channel.
            </p>
            <p className="m-0 pb-1">
                This only works in{" "}
                <span className="font-weight-bold">seepians</span> slack.
            </p>
        </span>
    );
};
