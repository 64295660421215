import React, { FC, useEffect, useState } from "react";
import { DSVRowArray } from "d3";
import { loadCsv, pastelStepColor } from "../../common/AnalyticsHelpers";
import { AnalyticsQueryType, AthenaDatasetResult } from "../../models/types";
import { ChartConfiguration } from "chart.js";
import { ChartJsWrapper } from "./ChartJsWrapper";

interface Props {
    data: AthenaDatasetResult[];
}

const MultiFunnelLineChartImplementation: FC<Props> = ({ data }) => {
    const [csvData, updateCsvData] = useState<DSVRowArray[]>([]);

    useEffect(() => {
        const fetchCsvData = async () => {
            const csvDatas: DSVRowArray[] = [];
            for (let i = 0; i < data.length; i++) {
                const element = data[i];

                for (let j = 0; j < element.dataset.length; j++) {
                    const dataset = element.dataset[j];
                    if (
                        dataset.success &&
                        dataset.message &&
                        dataset.type === AnalyticsQueryType.Funnel
                    ) {
                        csvDatas.push(await loadCsv(dataset.message));
                    }
                }
            }
            updateCsvData(csvDatas);
        };
        fetchCsvData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (csvData.length === 0) {
        return null;
    }

    const datasets = [];
    const labels: string[] = [];
    for (let i = 0; i < csvData.length; i++) {
        if (csvData[i] === undefined) {
            console.log(
                "[DEBUG] MultiFunnelLineChartImplementation found undefined data at index ",
                i
            );
            continue;
        }
        const element = csvData[i];
        const { columns, ...cleanData } = element;
        const dataset: number[] = [];
        for (const key in cleanData) {
            if (i === 0) {
                labels.push(cleanData[key].eventName || "");
            }
            dataset.push(Number(cleanData[key].events));
        }
        const color = pastelStepColor(i / (csvData.length - 1), 1);
        datasets.push({
            label: data[i].datasetName,
            data: dataset,
            borderColor: color,
            backgroundColor: color,
            pointBackgroundColor: color,
            pointBorderColor: color
        });
    }
    const config: ChartConfiguration = {
        type: "line",
        data: {
            labels: labels,
            datasets: datasets
        },
        options: {
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true
                }
            }
        }
    };
    return <ChartJsWrapper config={config} />;
};

export const MultiFunnelLineChart = React.memo(
    MultiFunnelLineChartImplementation
);
