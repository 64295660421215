import React, { FC, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useLocalState } from "../../graphql/hooks";

import AceEditor from "react-ace";

import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";

interface Props {
    jsonData: string;
    updateJsonData: (data: any) => void;
    updating: boolean;
    updateHasUnsavedData?: (hasUnsaved: boolean) => void;
    removeJsonData?: () => void;
}

export const EditableJsonView: FC<Props> = ({
    jsonData,
    updateJsonData,
    updating,
    updateHasUnsavedData,
    removeJsonData
}) => {
    const editorRef = useRef(null);
    const { useDarkMode } = useLocalState();
    const [json, setJson] = useState(jsonData);
    const [canSave, updateCanSave] = useState(false);

    useEffect(() => {
        const handleKeyDown = (e: any) => {
            if ((e.ctrlKey || e.metaKey) && e.key === "s") {
                e.preventDefault();
                updateCanSave(true);
            }
        };
        let editor: any = undefined;
        if (editorRef) {
            // @ts-ignore
            editor = editorRef.current?.editor;
            editor.container.addEventListener("keydown", handleKeyDown);
        }
        return () => {
            // @ts-ignore
            editor.container.removeEventListener("keydown", handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editorRef]);

    const tryUpdateJsonData = () => {
        try {
            updateJsonData(JSON.parse(json));
            checkHasUnsaved();
        } catch (e) {
            console.log(
                "[DEBUG] tryUpdateConfiguratorData failed with error: ",
                e
            );
        }
    };

    const checkHasUnsaved = () => {
        try {
            updateHasUnsavedData &&
                updateHasUnsavedData(
                    JSON.stringify(JSON.parse(json)) !==
                        JSON.stringify(JSON.parse(jsonData))
                );
        } catch (e) {
            console.log("[DEBUG] checkHasUnsaved invalid json ", e);
            return false;
        }
    };

    useEffect(() => {
        if (canSave) {
            updateCanSave(false);
            tryUpdateJsonData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canSave]);

    useEffect(() => {
        if (updateHasUnsavedData && !updating) {
            checkHasUnsaved();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updating]);

    return (
        <div className="fullSize p-2 mt-3">
            <AceEditor
                ref={editorRef}
                value={json}
                mode="json"
                theme={useDarkMode ? "monokai" : "github"}
                onChange={setJson}
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                name="configudator-json-editor"
                setOptions={{
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 4
                }}
                editorProps={{ $blockScrolling: true }}
                style={{
                    width: "100%",
                    minHeight: "calc(100% - 46px)",
                    border: "1px solid #ccc",
                    borderRadius: "10px"
                }}
                onBlur={checkHasUnsaved}
            />
            <div className="w-100 d-flex justify-content-center align-item-center align-content-center">
                <Button
                    className="m-2 w-25"
                    onClick={() => tryUpdateJsonData()}
                    disabled={updating}
                >
                    {updating ? "Updating..." : "Save"}
                </Button>
                {removeJsonData ? (
                    <Button
                        className="m-2 w-25"
                        variant="danger"
                        onClick={() => removeJsonData && removeJsonData()}
                        disabled={updating}
                    >
                        {updating ? "Updating..." : "Remove"}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};
