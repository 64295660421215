import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { AnalyticsLoadingState } from "../../models/types";
import { useLocalAnalyticsState } from "../../graphql/hooks";

const badgeColor = ["success", "secondary", "info", "primary", "danger"];

export const QueryHeader = () => {
    const { analyticsLoadingState } = useLocalAnalyticsState();
    const [loadingState, updateLoadingState] = useState(
        AnalyticsLoadingState.Ready
    );
    const [badgeVariant, updateBadgeVariant] = useState(
        badgeColor[analyticsLoadingState]
    );

    useEffect(() => {
        updateLoadingState(analyticsLoadingState);
        updateBadgeVariant(badgeColor[analyticsLoadingState]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [analyticsLoadingState]);

    return (
        <div className="d-flex align-items-center">
            <h3 className="text-inverse ms-3 d-flex align-items-center">
                Query
            </h3>
            <Badge bg={badgeVariant} className="ms-3 mb-1">
                {AnalyticsLoadingState[loadingState]}
            </Badge>
        </div>
    );
};
