import React, { FC } from "react";
import { AnalyticsQueryType, PlatformConfigData } from "../../../models/types";
import { QueryOptionsWrapper } from "./QueryOptionsWrapper";
import { GET_PLATFORM_CONFIG } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";

interface Props {
    queryType: AnalyticsQueryType;
}

export const QueryControllerViewAdvanced: FC<Props> = ({ queryType }) => {
    const { data: { getPlatformConfig } = {} } =
        useQuery<PlatformConfigData>(GET_PLATFORM_CONFIG);

    if (!getPlatformConfig) {
        return null;
    }

    return (
        <div className="my-3 text-inverse align-text-center">
            <QueryOptionsWrapper
                queryType={queryType}
                apiVersion={getPlatformConfig.analyticsApiVersion}
            />
        </div>
    );
};
