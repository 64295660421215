import React, { FC } from "react";
import { Badge, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
    AnalyticsNetworkBuildWithAnalyticsIdsData,
    AnalyticsNetworkBuildWithAnalyticsIdsVariables,
    ComparisonData,
    TempLink,
    TempLinkVariables
} from "../../../models/types";
import {
    GET_ANALYTICS_NETWORK_BUILDS_WITH_ANALYTICS_IDS,
    GET_TEMP_LINK
} from "../../../graphql/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import styles from "./NetworkBatchComparison.module.scss";

interface Props {
    data: {
        elements: ComparisonData[];
        adStart: number;
        ctaBtnPressed: number;
    };
    batchId: string;
    bestBatch: boolean;
    worstBatch: boolean;
    bestNetworkBuild?: ComparisonData;
    worstNetworkBuild?: ComparisonData;
    multipleBatches: boolean;
}

const NetworkBatchComparisonElementImplementation: FC<Props> = ({
    data,
    batchId,
    bestBatch,
    worstBatch,
    bestNetworkBuild,
    worstNetworkBuild,
    multipleBatches
}) => {
    if (data.elements.length === 0) {
        return null;
    }

    const buildIds = data.elements.map(element => element.build_id);

    const { data: analyticsBuilds } = useQuery<
        AnalyticsNetworkBuildWithAnalyticsIdsData,
        AnalyticsNetworkBuildWithAnalyticsIdsVariables
    >(GET_ANALYTICS_NETWORK_BUILDS_WITH_ANALYTICS_IDS, {
        variables: {
            analyticsBuildIds: buildIds
        }
    });

    const comparisonBuilds = data.elements.map(element => {
        return analyticsBuilds?.analyticsNetworkBuildsWithAnalyticsIds.find(
            build => build.analyticsBuildId === element.build_id
        );
    });

    const [getTempLink, { data: generateLink }] = useLazyQuery<
        TempLink,
        TempLinkVariables
    >(GET_TEMP_LINK, {
        fetchPolicy: "no-cache",
        refetchWritePolicy: "overwrite"
    });

    const getLink = async (productId: number, s3key: string) => {
        if (!productId || !s3key) {
            return;
        }

        try {
            const { data } = await getTempLink({
                variables: { productId, key: s3key }
            });
            if (data?.generateLink?.link) {
                window.open(data.generateLink.link, "_blank");
            } else {
                console.log("No link");
            }
        } catch (error) {
            console.log("Error", error);
        }
    };

    const s3keyBest = `${comparisonBuilds[0]?.build?.reportData?.info?.prefix}/${comparisonBuilds[0]?.filename}`;
    const productIdBest = Number(
        comparisonBuilds[0]?.build?.revision.project.product?.id
    );
    const s3keyWorst = `${comparisonBuilds[comparisonBuilds.length - 1]?.build?.reportData?.info?.prefix}/${comparisonBuilds[comparisonBuilds.length - 1]?.filename}`;
    const productIdWorst = Number(
        comparisonBuilds[comparisonBuilds.length - 1]?.build?.revision.project
            .product?.id
    );
    const average = (data.ctaBtnPressed / data.adStart) * 100;

    const getBatchTitle = (): string => {
        const networkBuild = comparisonBuilds.find(
            build => build?.analyticsBatchId === batchId
        );
        return `${networkBuild?.build?.revision?.project.name} / ${networkBuild?.build?.revision?.name} / Build ${networkBuild?.build?.id}`;
    };
    const batchTitle = getBatchTitle();

    const getBatchUrl = (): string => {
        const networkBuild = comparisonBuilds.find(
            build => build?.analyticsBatchId === batchId
        );
        return `/products/${networkBuild?.build?.revision.project.product?.id}/projects/${networkBuild?.build?.revision.project.id}/revisions/${networkBuild?.build?.revision.id}/history/${networkBuild?.build?.id}`;
    };
    const buildReportUrl = getBatchUrl();

    if (data.elements.length === 1) {
        return (
            <Card className="mt-2 text-inverse inner-card">
                <Card.Header>
                    <Link
                        to={buildReportUrl}
                        className={styles.titleLink}
                        target="_blank"
                        title="Open Build Report in New Tab"
                    >
                        {batchTitle}
                    </Link>
                    {multipleBatches ? (
                        <>
                            {bestBatch ? (
                                <Badge className="ms-2" bg="review">
                                    Best Performing Batch
                                </Badge>
                            ) : null}
                            {worstBatch ? (
                                <Badge className="ms-2" bg="demoted">
                                    Least Performing Batch
                                </Badge>
                            ) : null}
                        </>
                    ) : (
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 150, hide: 300 }}
                            overlay={
                                <Tooltip>
                                    No Batch Comparison Available - Query
                                    selection contains only one batch of network
                                    builds with valid analytics data
                                </Tooltip>
                            }
                        >
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                size="sm"
                                className="ms-2 text-secondary"
                            />
                        </OverlayTrigger>
                    )}
                </Card.Header>
                <Card.Body>
                    <span className="bread-text">
                        <b className="me-2">
                            CTR {data.elements[0].CTR.toFixed(1)}%
                        </b>
                        <span
                            className={styles.playableLink}
                            onClick={() => {
                                getLink(productIdBest, s3keyBest);
                            }}
                            title="Open the Playable in New Tab"
                        >
                            {comparisonBuilds[0]?.buildName}{" "}
                            {comparisonBuilds[0]?.network}
                        </span>
                        {!multipleBatches ? (
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 150, hide: 300 }}
                                overlay={
                                    <Tooltip>
                                        No Playable Comparison Available - Query
                                        selection contains only one network
                                        build with valid analytics data
                                    </Tooltip>
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                    className="ms-2 text-secondary"
                                />
                            </OverlayTrigger>
                        ) : null}
                    </span>
                    {multipleBatches ? (
                        <>
                            {bestNetworkBuild?.build_id ===
                            data.elements[0].build_id ? (
                                <Badge className="ms-3" bg="review">
                                    Best Performance
                                </Badge>
                            ) : null}
                            {worstNetworkBuild?.build_id ===
                                data.elements[0].build_id &&
                            worstNetworkBuild?.build_id !==
                                bestNetworkBuild?.build_id ? (
                                <Badge className="ms-3" bg="demoted">
                                    Lowest Performance
                                </Badge>
                            ) : null}
                        </>
                    ) : null}
                </Card.Body>
            </Card>
        );
    }

    const bestElement = (
        <>
            <span className="bread-text">
                <b className="me-2">
                    Highest CTR {data.elements[0].CTR.toFixed(1)}%
                </b>
                <span
                    className={styles.playableLink}
                    onClick={() => {
                        getLink(productIdBest, s3keyBest);
                    }}
                    title="Open the Playable in New Tab"
                >
                    {comparisonBuilds[0]?.buildName}{" "}
                    {comparisonBuilds[0]?.network}
                </span>
            </span>
            {bestNetworkBuild?.build_id === data.elements[0].build_id ? (
                <Badge className="ms-3" bg="review">
                    Best Performance
                </Badge>
            ) : null}
        </>
    );

    const worstElement = (
        <>
            <span className="bread-text">
                <b className="me-2">
                    Lowest CTR{" "}
                    {data.elements[data.elements.length - 1].CTR.toFixed(1)}%
                </b>
                <span
                    className={styles.playableLink}
                    onClick={() => {
                        getLink(productIdWorst, s3keyWorst);
                    }}
                    title="Open the Playable in New Tab"
                >
                    {comparisonBuilds[comparisonBuilds.length - 1]?.buildName}{" "}
                    {comparisonBuilds[comparisonBuilds.length - 1]?.network}
                </span>
            </span>
            {worstNetworkBuild?.build_id ===
            data.elements[data.elements.length - 1].build_id ? (
                <Badge className="ms-3" bg="demoted">
                    Lowest Performance
                </Badge>
            ) : null}
        </>
    );

    return (
        <Card className="mt-2 text-inverse inner-card">
            <Card.Header>
                <Link
                    to={buildReportUrl}
                    className={styles.titleLink}
                    target="_blank"
                    title="Open Build Report in New Tab"
                >
                    {batchTitle}
                </Link>
                {multipleBatches ? (
                    <>
                        {bestBatch ? (
                            <Badge className="ms-2" bg="review">
                                Best Performing Batch
                            </Badge>
                        ) : null}
                        {worstBatch ? (
                            <Badge className="ms-2" bg="demoted">
                                Least Performing Batch
                            </Badge>
                        ) : null}
                    </>
                ) : (
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 150, hide: 300 }}
                        overlay={
                            <Tooltip>
                                No Batch Comparison Available - Query selection
                                contains only one batch of network builds with
                                valid analytics data
                            </Tooltip>
                        }
                    >
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            size="sm"
                            className="ms-2 text-secondary"
                        />
                    </OverlayTrigger>
                )}
            </Card.Header>
            <Card.Body>
                <div>{bestElement}</div>
                <div className="mt-2">{worstElement}</div>
                <div className="mt-3 bread-text">
                    Batch Average CTR: {average.toFixed(1)}%
                </div>
            </Card.Body>
        </Card>
    );
};

export const NetworkBatchComparisonElement = React.memo(
    NetworkBatchComparisonElementImplementation
);
