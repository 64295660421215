import React, { Component, ReactElement } from "react";
import { Card } from "react-bootstrap";
import { SimpleLineChart } from "../../charts/SimpleLineChart";
import { MultiLineChart } from "../../charts/MultiLineChart";
import { AnalyticsQueryType, AthenaDatasetResult } from "../../../models/types";
import { DSVRowArray } from "d3";
import { loadCsv } from "../../../common/AnalyticsHelpers";
import { convertSQLDatesToChartDates } from "../../../common/Helpers";
import { OverlayBadge } from "../../simple/OverlayBadge";

interface ComponentState {
    rawSetData: DayFunnelDataSet[];
    expectedCount: number;
    loadedCount: number;
}

interface Props {
    data: AthenaDatasetResult[];
}

interface DayFunnelDataSet {
    csvData: DSVRowArray[];
    datasetName: string;
}

const compareDates = (dateString1: string, dateString2: string) => {
    const date1 = new Date(dateString1);
    const date2 = new Date(dateString2);

    return date1.getTime() - date2.getTime();
};

export class DayFunnelChartsView extends Component<Props> {
    state: ComponentState = {
        rawSetData: [],
        expectedCount: -1,
        loadedCount: 0
    };

    shouldComponentUpdate(nextProps: Props, nextState: ComponentState) {
        return (
            this.state.loadedCount !== nextState.loadedCount &&
            nextState.loadedCount === this.state.expectedCount
        );
    }

    componentDidMount(): void {
        const { data } = this.props;
        let expectedCount = 0;
        const relevantDataSets: any[] = [];
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            for (let j = 0; j < element.dataset.length; j++) {
                if (
                    element.dataset[j].type ===
                    AnalyticsQueryType.DaySelectionFunnel
                ) {
                    expectedCount++;
                    if (element.dataset[j].success) {
                        relevantDataSets.push({
                            datasetName: element.datasetName,
                            message: element.dataset[j].message
                        });
                    }
                }
            }
        }

        this.setState({
            expectedCount: expectedCount,
            rawSetData: []
        });

        for (let i = 0; i < relevantDataSets.length; i++) {
            const element = relevantDataSets[i];
            loadCsv(element.message)
                .then(result => {
                    const data = [
                        ...this.state.rawSetData,
                        {
                            csvData: result,
                            datasetName: element.datasetName
                        }
                    ];
                    const loaded = this.state.loadedCount + 1;
                    this.setState({
                        rawSetData: data,
                        loadedCount: loaded
                    });
                })
                .catch(error => console.log("[DEBUG] error ", error));
        }
    }

    render() {
        const { rawSetData } = this.state;
        if (rawSetData.length !== this.state.expectedCount) {
            return null;
        }

        const elements: ReactElement[] = [];
        if (rawSetData.length === 1) {
            // @ts-ignore
            const { columns, ...cleanData } = rawSetData[0].csvData;
            const headers: string[] = [];
            const datasets: any[][] = [];
            const dataSetNames: string[] = [];
            const eventCounts: number[][] = [];

            for (let i = 1; i < columns.length; i++) {
                headers.push(columns[i]);
                datasets.push([]);
                eventCounts.push([]);
            }

            for (let i = 0; i < rawSetData.length; i++) {
                dataSetNames.push(rawSetData[i].datasetName);
            }

            const labels: string[] = [];
            let last = 100;

            for (const key in cleanData) {
                let lastColumnName = "";
                for (let i = 0; i < columns.length; i++) {
                    const columnName = columns[i];
                    if (i === 0) {
                        labels.push(
                            convertSQLDatesToChartDates(
                                // @ts-ignore
                                cleanData[key][columnName]
                            )
                        );
                    } else {
                        const currentCount = Number(cleanData[key][columnName]);
                        eventCounts[i - 1].push(currentCount);
                        if (i === 1) {
                            datasets[i - 1].push(currentCount);
                            if (lastColumnName !== columnName) {
                                lastColumnName = columnName;
                            }
                        } else {
                            // @ts-ignore
                            last = Number(cleanData[key][lastColumnName]);
                            const percentage = (currentCount / last) * 100;
                            datasets[i - 1].push(percentage.toFixed(2));
                        }
                    }
                }
            }

            for (let i = 0; i < columns.length - 1; i++) {
                const legend =
                    i === 0 ? "event count" : "users (%) from previous event";
                const isPercentage = i !== 0;
                elements.push(
                    <Card key={i} className="mt-2 mb-4 text-inverse">
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <h5 className="text-inverse-75">
                                Day funnel - {headers[i]}
                            </h5>
                            <OverlayBadge
                                badgeHeader="?"
                                badgeVariant="secondary"
                                className="float-end"
                                overlayText="Day funnel charts give insight to daily fluctuation in the funnel events - the first chart is drawn with total event counts, and the next with amount of users (%) transferred from the previous event (total counts included in tooltips)."
                            ></OverlayBadge>
                        </Card.Header>
                        <Card.Body>
                            <div>
                                <SimpleLineChart
                                    label={legend}
                                    labels={labels}
                                    dataset={datasets[i]}
                                    isPercentage={isPercentage}
                                    eventCounts={eventCounts[i]}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                );
            }
        } else {
            const comparisons = [];
            const headers: string[] = [];
            const dataSetNames: string[] = [];
            let labels: string[] = [];
            const eventCountsComparisons = [];

            for (let index = 0; index < rawSetData.length; index++) {
                // @ts-ignore
                const { columns, ...cleanData } = rawSetData[index].csvData;
                for (const key in cleanData) {
                    const label = cleanData[key][columns[0]];
                    // @ts-ignore
                    if (!labels.includes(label)) {
                        // @ts-ignore
                        labels.push(label);
                    }
                }
            }

            labels = labels.sort(compareDates);

            for (let index = 0; index < rawSetData.length; index++) {
                // @ts-ignore
                const { columns, ...cleanData } = rawSetData[index].csvData;
                const datasets: any[][] = [];
                const eventCounts: any[][] = [];

                for (let i = 1; i < columns.length; i++) {
                    if (index === 0) {
                        headers.push(columns[i]);
                    }
                    // datasets.push([]);
                    datasets.push(Array(labels.length).fill(0));
                    eventCounts.push(Array(labels.length).fill(0));
                }
                if (index === 0) {
                    for (let i = 0; i < rawSetData.length; i++) {
                        dataSetNames.push(rawSetData[i].datasetName);
                    }
                }

                let last = 100;
                for (const key in cleanData) {
                    let lastColumnName = "";
                    for (let i = 0; i < columns.length; i++) {
                        if (i === 0) {
                            continue;
                        }
                        const columnName = columns[i];
                        // @ts-ignore
                        const date = cleanData[key]["eventDate"];
                        const targetIndex = labels.indexOf(date);
                        const currentCount = Number(cleanData[key][columnName]);

                        if (i === 1) {
                            datasets[i - 1][targetIndex] = currentCount;
                            eventCounts[i - 1][targetIndex] = currentCount;
                            if (lastColumnName !== columnName) {
                                lastColumnName = columnName;
                            }
                        } else {
                            // @ts-ignore
                            last = Number(cleanData[key][lastColumnName]);
                            const percentage = (currentCount / last) * 100;
                            datasets[i - 1][targetIndex] =
                                percentage.toFixed(2);
                            eventCounts[i - 1][targetIndex] = currentCount;
                        }
                    }
                }
                comparisons.push(datasets);
                eventCountsComparisons.push(eventCounts);
            }

            // @ts-ignore
            const { columns } = rawSetData[0].csvData;
            labels = labels.map(label => convertSQLDatesToChartDates(label));
            for (let i = 0; i < columns.length - 1; i++) {
                const datas = [];
                const eventCountsDatas = [];
                for (let j = 0; j < comparisons.length; j++) {
                    datas.push(comparisons[j][i]);
                    eventCountsDatas.push(eventCountsComparisons[j][i]);
                }
                const isPercentage = i !== 0;
                elements.push(
                    <Card key={i} className="mb-4 mt-2 text-inverse">
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <h5>
                                <span className="text-inverse-75">
                                    Day funnel{" "}
                                </span>
                                {headers[i]}
                            </h5>
                            <OverlayBadge
                                badgeHeader="?"
                                badgeVariant="secondary"
                                className="float-end"
                                overlayText="Day funnel charts give insight to daily fluctuation in the funnel events - the first chart is drawn with total event counts, and the next with amount of users (%) transferred from the previous event (total counts included in tooltips)."
                            ></OverlayBadge>
                        </Card.Header>
                        <Card.Body>
                            <div>
                                <MultiLineChart
                                    key={i + headers[i]}
                                    datasetNames={dataSetNames}
                                    labels={labels}
                                    datasets={datas}
                                    isPercentage={isPercentage}
                                    eventCounts={eventCountsDatas}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                );
            }
        }

        return <div className="my-4">{elements}</div>;
    }
}
