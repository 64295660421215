import React, { FC, ReactElement, useEffect, useState } from "react";
import {
    Button,
    FormControl,
    InputGroup,
    ListGroup,
    ListGroupItem,
    Modal
} from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { GET_VALID_LOCALIZATION_TAGS } from "../../graphql/queries";
import { useLocalState } from "../../graphql/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import "./Modals.scss";

interface SubComponentProps {
    tag: string;
    useDarkMode: boolean;
    fullString: string;
}

const LocalizationTagElement: FC<SubComponentProps> = ({
    tag,
    useDarkMode,
    fullString
}) => {
    let copyTimeout: NodeJS.Timeout | null = null;
    const [tagCopied, updateTagCopied] = useState(false);

    useEffect(() => {
        return () => {
            if (copyTimeout) {
                clearTimeout(copyTimeout);
            }
        };
    }, []);

    const copyToClipboard = (command: string) => {
        navigator.clipboard
            .writeText(command)
            .then(() => {
                updateTagCopied(true);
                copyTimeout = setTimeout(() => updateTagCopied(false), 500);
            })
            .catch(error => {
                console.log(
                    "[DEBUG] copy to clipboard failed with error: ",
                    error
                );
            });
    };

    return (
        <ListGroupItem variant={useDarkMode ? "dark" : ""}>
            {fullString}
            <Button
                className="ms-auto btn-sm float-end"
                variant={tagCopied ? "success" : "primary"}
                title="Copy tag to clipboard"
                onClick={(event: any) => {
                    event.preventDefault();
                    event.stopPropagation();
                    copyToClipboard(tag);
                }}
            >
                <FontAwesomeIcon icon={faClipboard} />
            </Button>
        </ListGroupItem>
    );
};

interface Props {
    show: boolean;
    onClose: () => void;
}

export const LocalizationTagsModal: FC<Props> = ({ show, onClose }) => {
    const [search, setSearch] = useState("");
    const { useDarkMode } = useLocalState();

    const { data } = useQuery(GET_VALID_LOCALIZATION_TAGS, {
        fetchPolicy: "cache-first"
    });

    const validLocalizationTagsAsList: ReactElement[] = [];
    if (data && data.getValidLocalizationTags) {
        const searchValue = search.toLowerCase();
        for (const key in data.getValidLocalizationTags) {
            const fullString = `${key} (${data.getValidLocalizationTags[key]})`;
            if (
                search === "" ||
                fullString.toLowerCase().includes(searchValue)
            ) {
                validLocalizationTagsAsList.push(
                    <LocalizationTagElement
                        key={key}
                        fullString={fullString}
                        tag={key}
                        useDarkMode={useDarkMode}
                    />
                );
            }
        }
    }

    return (
        <Modal
            centered
            show={show}
            onHide={onClose}
            backdrop="static"
            className={useDarkMode ? "modal-dark" : ""}
        >
            <Modal.Header closeButton>
                <Modal.Title>Supported Localization Tags</Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{ maxHeight: "600px", overflow: "auto" }}
                className="modal-body p-2"
            >
                <InputGroup className="mb-2">
                    <InputGroup.Text>Search</InputGroup.Text>
                    <FormControl
                        placeholder="Search"
                        value={search}
                        onChange={(event: any) => {
                            setSearch(event.target.value);
                        }}
                    />
                </InputGroup>
                <ListGroup>{validLocalizationTagsAsList}</ListGroup>
            </Modal.Body>
        </Modal>
    );
};
