import React, { Component } from "react";
import { pastelStepColor } from "../../common/AnalyticsHelpers";
import { ChartConfiguration } from "chart.js";
import { ChartJsWrapper } from "./ChartJsWrapper";

interface Props {
    datasetNames: string[];
    labels: string[];
    datasets: any[];
    isPercentage: boolean;
    eventCounts: any[];
}

export class MultiLineChart extends Component<Props> {
    shouldComponentUpdate() {
        return false;
    }

    render() {
        const { datasetNames, labels, datasets, eventCounts, isPercentage } =
            this.props;
        if (!datasets) {
            return null;
        }

        const comparisonData = [];
        for (let i = 0; i < datasets.length; i++) {
            const dataset = datasets[i];
            comparisonData.push({
                label: datasetNames[i],
                data: dataset,
                backgroundColor: pastelStepColor(i, 1),
                borderColor: pastelStepColor(i, 1)
            });
        }
        const config: ChartConfiguration = {
            type: "line",
            data: {
                labels: labels,
                datasets: comparisonData
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        ticks: {
                            callback: function (value) {
                                if (isPercentage) {
                                    return value + "%";
                                } else {
                                    return value.toLocaleString();
                                }
                            },
                            font: {
                                size: 13
                            }
                        },
                        title: {
                            display: true,
                            text: isPercentage
                                ? "Users (%) from previous event"
                                : "Event count",
                            font: { size: 13 },
                            color: "#A9A9A9"
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                let label = context.dataset.label || "";
                                if (label) {
                                    label += ": ";
                                }
                                if (context.parsed.y !== null) {
                                    if (isPercentage) {
                                        label +=
                                            context.parsed.y.toFixed(1) + "%";
                                        const count =
                                            eventCounts[context.datasetIndex][
                                                context.dataIndex
                                            ];
                                        label += ` (${count.toLocaleString()})`;
                                    } else {
                                        label +=
                                            context.parsed.y.toLocaleString();
                                    }
                                }
                                return label;
                            }
                        }
                    }
                }
            }
        };
        return <ChartJsWrapper config={config} />;
    }
}
