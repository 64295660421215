import React from "react";
import { AthenaDatasetResult } from "../../../models/types";
import { NetworkBuildComparison } from "./NetworkBuildComparison";

interface Props {
    data: AthenaDatasetResult[];
}

export const NetworkBuildComparisonWrapper: React.FC<Props> = ({ data }) => {
    return (
        <div>
            {data.map((dataset, index) => (
                <NetworkBuildComparison key={index} dataset={dataset.dataset} />
            ))}
        </div>
    );
};
