import React, { FC } from "react";
import {
    AnalyticsDailyListData,
    DailyAnalyticsListElement,
    DailyAnalyticsType
} from "../../../models/types";
import { useQuery } from "@apollo/client";
import { GET_DAILY_ANALYTICS_LIST } from "../../../graphql/queries";

interface Props {
    header: string;
    tierReference: string;
    dailyAnalyticsType: DailyAnalyticsType;
    descending?: boolean;
    count?: number;
}

const DailyListImplementation: FC<Props> = ({
    header,
    tierReference,
    dailyAnalyticsType,
    descending = true,
    count = 5
}) => {
    const minimumImpressions = 1000;
    const { data } = useQuery<AnalyticsDailyListData>(
        GET_DAILY_ANALYTICS_LIST,
        {
            variables: {
                input: {
                    tierReference: tierReference,
                    dailyAnalyticsType: dailyAnalyticsType,
                    descending: descending,
                    count: count,
                    minimumImpressions: minimumImpressions
                }
            }
        }
    );
    console.log("[DEBUG] data ", data);

    const results = data?.getDailyAnalyticsList.flatMap(
        (element: DailyAnalyticsListElement, index: number) =>
            element.ctr && element.ctr > 0 ? (
                <li key={index}>
                    {element.product?.name}: {element.ctr.toFixed(1) + "%"}
                </li>
            ) : (
                []
            )
    );

    if (results?.length === 0) {
        return (
            <div>
                <p>{header}</p>
                <p>No valid data for this time period</p>
            </div>
        );
    }

    return (
        <div>
            <p>{header}</p>
            <ul>{results}</ul>
        </div>
    );
};

export const DailyList = React.memo(DailyListImplementation);
