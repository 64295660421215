import React, { useEffect, useState } from "react";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { Button } from "react-bootstrap";
import { QueryState } from "../../../models/types";
import { LoadingSpinner } from "../../simple/LoadingSpinner";

export const AnalyticsLoadingButtonWithProgress = () => {
    const { analyticsTask } = useLocalAnalyticsState();
    const [queryState, updateQueryState] = useState(QueryState.Querying);

    useEffect(() => {
        if (analyticsTask && analyticsTask.subQueries) {
            if (
                analyticsTask.subQueries.completedCount ===
                analyticsTask.subQueries.subTaskCount
            ) {
                updateQueryState(QueryState.Processing);
            }
        }
    }, [analyticsTask]);

    return (
        <Button className="disabled">
            {queryState === QueryState.Querying ? (
                <LoadingSpinner
                    header={`Querying (${analyticsTask?.subQueries?.completedCount}/${analyticsTask?.subQueries?.subTaskCount})`}
                    size="sm"
                />
            ) : null}
            {queryState === QueryState.Processing ? (
                <LoadingSpinner header="Processing..." size="sm" />
            ) : null}
        </Button>
    );
};
