import React, { Component, ReactElement } from "react";
import { DSVRowArray } from "d3";
import { loadCsv, pastelStepColor } from "../../common/AnalyticsHelpers";
import { Card, Col, InputGroup, Row } from "react-bootstrap";
import { OverlayBadge } from "../simple/OverlayBadge";
import { AnalyticsQueryType, AthenaDatasetResult } from "../../models/types";
import { ChartConfiguration } from "chart.js";
import { ChartJsWrapper } from "./ChartJsWrapper";

interface Props {
    data: AthenaDatasetResult[];
    darkMode: boolean;
}

interface ComponentState {
    showMax: boolean;
    csvData: DSVRowArray | undefined;
}

export class TimeLineChartSegmented extends Component<Props, ComponentState> {
    state: ComponentState = {
        showMax: true,
        csvData: undefined
    };

    shouldComponentUpdate(nextProps: Props, nextState: ComponentState) {
        return (
            this.state.showMax !== nextState.showMax ||
            this.state.csvData !== nextState.csvData
        );
    }

    componentDidMount(): void {
        const { data } = this.props;
        const funnelData = data[0].dataset.find(
            element => element.type === AnalyticsQueryType.TimeDistribution
        );
        if (funnelData?.success && funnelData.message) {
            loadCsv(funnelData.message)
                .then(result => {
                    this.setState({ csvData: result });
                })
                .catch(error => console.log("[DEBUG] error ", error));
        }
    }

    render() {
        const { darkMode } = this.props;
        const { csvData, showMax } = this.state;
        if (!csvData) {
            return null;
        }
        let filteredData = csvData.filter(
            element => element.sequence !== undefined
        );
        if (!showMax) {
            filteredData = filteredData.filter(
                element => element.interval !== "60+"
            );
        }
        const sortedData = filteredData.sort((a, b) =>
            // @ts-ignore
            a.interval > b.interval ? 1 : -1
        );
        let maxSequence = 1;
        for (let i = 0; i < csvData.length; i++) {
            const sequence = Number(csvData[i].sequence);
            if (sequence > maxSequence) {
                maxSequence = sequence;
            }
        }
        const elements: ReactElement[] = [];
        for (let i = 1; i <= maxSequence; i++) {
            const labels: string[] = [];
            const totals: number[] = [];
            let chartLabel: string | undefined = undefined;

            for (let j = 0; j < sortedData.length; j++) {
                const label = sortedData[j].interval;
                const sequence = Number(sortedData[j].sequence);
                const total = Number(sortedData[j].total);
                if (sequence === i && label && sequence) {
                    if (!chartLabel) {
                        chartLabel = sortedData[j].eventName;
                    }
                    labels.push(label);
                    totals.push(total);
                }
            }
            const config: ChartConfiguration = {
                type: "bar",
                data: {
                    labels: labels,
                    datasets: [
                        {
                            type: "bar",
                            label: chartLabel,
                            data: totals,
                            backgroundColor: pastelStepColor(i / maxSequence, 1)
                        }
                    ]
                },
                options: {
                    // responsive: true,
                    maintainAspectRatio: false
                }
            };
            elements.push(
                <Col md={Math.floor(12 / maxSequence)} key={i}>
                    <div>
                        <ChartJsWrapper config={config} />
                    </div>
                </Col>
            );
        }

        return (
            <Card className="text-inverse mt-4">
                <Card.Header>
                    <Row className="d-flex align-items-center">
                        <Col>
                            <h5 className="text-inverse-75">
                                Time Distribution
                            </h5>
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center">
                            <InputGroup className="custom-input-background d-flex justify-content-end me-3">
                                <InputGroup.Checkbox
                                    title="Show data points collected after 60 seconds"
                                    aria-label="Show data points collected after 60 seconds"
                                    checked={showMax}
                                    onChange={() => {
                                        this.setState(state => ({
                                            showMax: !showMax
                                        }));
                                    }}
                                />
                                <InputGroup.Text className="text-inverse-50 bread-text p-0">
                                    Show 60+
                                </InputGroup.Text>
                            </InputGroup>
                            <OverlayBadge
                                badgeHeader="?"
                                badgeVariant="secondary"
                                className=""
                                overlayText="Time distribution excludes duplicates (etc), so it might not match funnel results exactly."
                            ></OverlayBadge>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row>{elements}</Row>
                </Card.Body>
            </Card>
        );
    }
}
