import React, { FC } from "react";
import { AnalyticsQueryType, HeatmapData } from "../../../models/types";
import { FunnelElements } from "../../simple/analytics/FunnelElements";
import { QueryOptions } from "./QueryOptions";
import { Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import { DSVRowArray } from "d3";
import { QuerySelection } from "./QuerySelection";
import { useLocalAnalyticsState, useLocalState } from "../../../graphql/hooks";
import { OverlayBadge } from "../../simple/OverlayBadge";
import { FunnelVisualChart } from "../../charts/FunnelVisualChart";
import { FunnelBarChart } from "../../charts/FunnelBarChart";
import { MultiFunnelBarChart } from "../../charts/MultiFunnelBarChart";
import { FunnelLineChart } from "../../charts/FunnelLineChart";
import { MultiFunnelLineChart } from "../../charts/MultiFunnelLineChart";
import cx from "classnames";

const SINGLE_FUNNEL_QUERY_COUNT = 1;

interface Props {
    queryType: AnalyticsQueryType;
    apiVersion: string;
    updateHeatmapDataset?: (dataset: HeatmapData | undefined) => void;
    updateLandscapeBaseHeatmapDataset?: (
        dataset: HeatmapData | undefined
    ) => void;
    updateTimeDistributionDataset?: (dataset: DSVRowArray | undefined) => void;
}

export const QueryOptionsWrapper: FC<Props> = ({ queryType, apiVersion }) => {
    const { useDarkMode } = useLocalState();
    const { funnelAnalyticsResults } = useLocalAnalyticsState();

    const comparison =
        funnelAnalyticsResults.length > SINGLE_FUNNEL_QUERY_COUNT;

    return (
        <>
            <Row>
                <Col lg={7}>
                    <QuerySelection />
                </Col>
                <Col lg={5}>
                    <QueryOptions
                        apiVersion={apiVersion}
                        queryType={queryType}
                    />
                </Col>
            </Row>
            <Row>
                <Col
                    className={cx("ps-2 text-inverse-75", {
                        "col-12": funnelAnalyticsResults.length === 0,
                        "col-12 col-lg-7": funnelAnalyticsResults.length > 0
                    })}
                >
                    <FunnelElements
                        queryType={queryType}
                        apiVersion={apiVersion}
                    />
                </Col>
                {funnelAnalyticsResults.length > 0 ? (
                    <Col lg={5}>
                        <Card className="text-inverse">
                            <Card.Header className="d-flex text-inverse-75 justify-content-between align-items-center">
                                <h5>Funnel</h5>
                                <OverlayBadge
                                    badgeHeader="?"
                                    badgeVariant="secondary"
                                    className="float-end"
                                    overlayText="Select funnel mode from the tabs"
                                ></OverlayBadge>
                            </Card.Header>
                            <Card.Body className="pb-4">
                                <Tabs
                                    defaultActiveKey="visual"
                                    className="mb-4"
                                >
                                    <Tab
                                        className="px-2"
                                        eventKey="visual"
                                        title="Visual Chart"
                                    >
                                        <Row>
                                            {!comparison ? (
                                                <Col xs="auto">
                                                    <FunnelVisualChart
                                                        data={
                                                            funnelAnalyticsResults
                                                        }
                                                        darkMode={useDarkMode}
                                                    />
                                                </Col>
                                            ) : (
                                                <>
                                                    {funnelAnalyticsResults.map(
                                                        (dataset, index) => (
                                                            <Col
                                                                className="text-center"
                                                                xs="auto"
                                                                key={index}
                                                            >
                                                                <div className="bread-text mb-2">
                                                                    {
                                                                        dataset.datasetName
                                                                    }
                                                                </div>
                                                                <div className="p-2 mb-3">
                                                                    <FunnelVisualChart
                                                                        data={[
                                                                            dataset
                                                                        ]}
                                                                        darkMode={
                                                                            useDarkMode
                                                                        }
                                                                    />
                                                                </div>
                                                            </Col>
                                                        )
                                                    )}
                                                </>
                                            )}
                                        </Row>
                                    </Tab>
                                    <Tab eventKey="bar" title="Bar Chart">
                                        <div>
                                            {!comparison ? (
                                                <FunnelBarChart
                                                    data={
                                                        funnelAnalyticsResults
                                                    }
                                                />
                                            ) : (
                                                <MultiFunnelBarChart
                                                    data={
                                                        funnelAnalyticsResults
                                                    }
                                                />
                                            )}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="line" title="Line Chart">
                                        <div className="d-flex justify-content-center">
                                            {!comparison ? (
                                                <FunnelLineChart
                                                    data={
                                                        funnelAnalyticsResults
                                                    }
                                                    darkMode={useDarkMode}
                                                />
                                            ) : (
                                                <MultiFunnelLineChart
                                                    data={
                                                        funnelAnalyticsResults
                                                    }
                                                />
                                            )}
                                        </div>
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Card>
                    </Col>
                ) : null}
            </Row>
        </>
    );
};
