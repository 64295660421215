import React from "react";
import { DailyList } from "../../simple/analytics/DailyList";
import { DailyAnalyticsType } from "../../../models/types";

const AnalyticsDashboardImplementation = () => {
    return (
        <div className="mt-4 px-3 text-inverse">
            <p>Analytics Dashboard</p>
            <DailyList
                header="Top 5 Products 24h"
                tierReference="products"
                dailyAnalyticsType={DailyAnalyticsType.LastDay}
            />
            <DailyList
                header="Top 5 Products Last Week"
                tierReference="products"
                dailyAnalyticsType={DailyAnalyticsType.LastWeek}
            />
            <DailyList
                header="Top 5 Products Last Month"
                tierReference="products"
                dailyAnalyticsType={DailyAnalyticsType.LastMonth}
            />
            <DailyList
                header="Top 5 Products Last 3 Months"
                tierReference="products"
                dailyAnalyticsType={DailyAnalyticsType.LastQuarter}
            />
            <DailyList
                header="Top 5 Products Last 6 Months"
                tierReference="products"
                dailyAnalyticsType={DailyAnalyticsType.LastHalf}
            />
            <DailyList
                header="Top 5 Products Last Year"
                tierReference="products"
                dailyAnalyticsType={DailyAnalyticsType.LastYear}
            />
            <DailyList
                header="Top 5 Products Last Calendar Week"
                tierReference="products"
                dailyAnalyticsType={DailyAnalyticsType.LastCalendarWeek}
            />
            <DailyList
                header="Top 5 Products Last Calendar Month (TODO: which month)"
                tierReference="products"
                dailyAnalyticsType={DailyAnalyticsType.LastCalendarMonth}
            />
            <DailyList
                header="Top 5 Products Last Calendar Quarter (TODO: which QX)"
                tierReference="products"
                dailyAnalyticsType={DailyAnalyticsType.LastCalendarQuarter}
            />
            <DailyList
                header="Top 5 Products Last Calendar Half"
                tierReference="products"
                dailyAnalyticsType={DailyAnalyticsType.LastCalendarHalf}
            />
            <DailyList
                header="Top 5 Products Last Calendar Year"
                tierReference="products"
                dailyAnalyticsType={DailyAnalyticsType.LastCalendarYear}
            />
        </div>
    );
};
export const AnalyticsDashboard = React.memo(AnalyticsDashboardImplementation);
