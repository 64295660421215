import React from "react";
import { OverlayBadge } from "./OverlayBadge";
import { useEditPanelState } from "../../graphql/hooks";

export const MutatorConfigCheckOverlayBadge = () => {
    const { mutatorConfigCheck } = useEditPanelState();

    if (!mutatorConfigCheck) {
        return null;
    }

    return (
        <OverlayBadge
            badgeHeader="Invalid Mutator Config!"
            badgeVariant="danger"
            overlayText="Missing or invalid mutator config"
            className="mt-2 p-1 me-2"
        />
    );
};
