import React, { useState } from "react";
import {
    Alert,
    Button,
    Card,
    Col,
    Dropdown,
    DropdownButton,
    Form,
    InputGroup,
    Row
} from "react-bootstrap";
import {
    Organization,
    OrganizationData,
    UserInvitationData,
    UserInvitationVariables
} from "../../../models/types";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ORGANIZATIONS } from "../../../graphql/queries";
import { validateEmail } from "../../../common/Helpers";
import { CREATE_USER_INVITATION } from "../../../graphql/mutations";
import { Invitations } from "./Invitations";

export const InviteTools = () => {
    const [loading, updateLoading] = useState(false);
    const [email, updateEmail] = useState("");
    const [organization, updateOrganization] = useState<
        Organization | undefined
    >(undefined);
    const [error, updateError] = useState<string | undefined>(undefined);
    const [message, updateMessage] = useState<string | undefined>(undefined);
    const [errorMessage, updateErrorMessage] = useState<string | undefined>(
        undefined
    );
    const { data: { organizations } = {} } =
        useQuery<OrganizationData>(GET_ORGANIZATIONS);

    const [createInvitationMutation] = useMutation<
        UserInvitationData,
        UserInvitationVariables
    >(CREATE_USER_INVITATION);

    const generateOrganizationAlternatives = organizations?.map(
        (organization: Organization, index: number) => (
            <Dropdown.Item key={index} eventKey={organization.id.toString()}>
                {organization.name}
            </Dropdown.Item>
        )
    );

    const sendInvite = () => {
        if (!email || !organization) {
            return;
        }
        updateLoading(true);
        createInvitationMutation({
            variables: {
                email: email,
                organizationId: organization.id
            }
        })
            .then(result => {
                console.log(
                    "[DEBUG] createInvitationMutation success: ",
                    result
                );
                updateMessage(
                    `Invitation sent with the following link: ${result.data?.userInvitation.url}`
                );
                setTimeout(() => updateMessage(undefined), 10000);
            })
            .catch(error => {
                console.log(
                    "[DEBUG] createInvitationMutation failed with error: ",
                    error
                );
                updateErrorMessage(
                    `Sending invitation failed with error: ${error.message}`
                );
                setTimeout(() => updateErrorMessage(undefined), 5000);
            })
            .finally(() => {
                updateLoading(false);
            });
    };

    return (
        <>
            <Card className="inner-card text-inverse m-3 p-0">
                <Card.Header>Invite User</Card.Header>
                <Card.Body>
                    <Row className="d-flex justify-content-center">
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Email</InputGroup.Text>
                            <Form.Control
                                type="text"
                                name="projectName"
                                value={email}
                                onChange={(event: any) => {
                                    updateEmail(event.target.value);
                                }}
                                onBlur={() => {
                                    if (!validateEmail(email)) {
                                        updateError("Invalid email");
                                    } else {
                                        updateError(undefined);
                                    }
                                }}
                            />
                        </InputGroup>
                        {error !== undefined ? (
                            <Alert variant="danger">{error}</Alert>
                        ) : null}
                    </Row>
                    <Row className="d-flex justify-content-center">
                        <Col className="col-12 mt-2 mb-2">
                            <h5>Organization</h5>
                            <DropdownButton
                                title={
                                    organization === undefined
                                        ? "Select Organization"
                                        : organization.name
                                }
                                variant="primary"
                                className="text-center m-2"
                                onSelect={(
                                    selectedItem: string | null | undefined
                                ) => {
                                    if (selectedItem) {
                                        const selectedOrganization =
                                            organizations?.find(
                                                element =>
                                                    Number(element.id) ===
                                                    Number(selectedItem)
                                            );
                                        if (selectedOrganization) {
                                            updateOrganization(
                                                selectedOrganization
                                            );
                                        }
                                    }
                                }}
                            >
                                {generateOrganizationAlternatives}
                            </DropdownButton>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    {message ? (
                        <Alert variant="success">{message}</Alert>
                    ) : null}
                    {errorMessage ? (
                        <Alert variant="danger">{errorMessage}</Alert>
                    ) : null}
                    <Button
                        className={
                            validateEmail(email) &&
                            error === undefined &&
                            organization !== undefined &&
                            !loading
                                ? "success"
                                : "disabled"
                        }
                        onClick={sendInvite}
                    >
                        Invite
                    </Button>
                </Card.Footer>
            </Card>
            <Invitations />
        </>
    );
};
