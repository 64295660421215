import React, { FC, useState } from "react";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { Col, Collapse, Row } from "react-bootstrap";
import { AnalyticsFunnelViewSelectionToggle } from "../../buttons/analytics/AnalyticsFunnelViewSelectionToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faChevronUp,
    faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import { getTree } from "../../../common/AnalyticsHelpers";
import { DailyBadges } from "./DailyBadges";
import { UserQueryBadges } from "./UserQueryBadges";
import { BatchBuild } from "../../../models/types";
import { AnalyticsNetworkBuilds } from "../../modals/analytics/AnalyticsNetworkBuilds";
import { AnalyticsFunnelViewComparisonToggle } from "../../buttons/analytics/AnalyticsFunnelViewComparisonToggle";
import styles from "./FunnelBatchBuild.module.scss";

interface Props {
    build: BatchBuild;
    handleShow: (item: BatchBuild) => void;
}

const FunnelBatchBuildImplementation: FC<Props> = ({ build, handleShow }) => {
    const analyticsState = useLocalAnalyticsState();
    const myTree = getTree(analyticsState, Number(build.id), "builds");
    const [show, setShow] = useState(
        (myTree?.selection?.networkBuilds &&
            myTree.selection.networkBuilds.length > 0) ||
            false
    );

    return (
        <div className={styles.container}>
            <Row
                className={`my-1 align-items-center ps-2 g-0 
                    
                    ${show ? "" : "list-row"}`}
            >
                <Col
                    className={`${styles.selectionToggleCol} d-flex align-items-center`}
                >
                    <AnalyticsFunnelViewSelectionToggle
                        className="mx-3"
                        reference="build"
                        build={build}
                    />
                    <span
                        onClick={() => setShow(!show)}
                        className="mouseHover bread-text"
                    >
                        Build {build.id}
                    </span>
                    <AnalyticsFunnelViewComparisonToggle
                        reference="build"
                        className="ms-2"
                        build={build}
                    />
                    <FontAwesomeIcon
                        onClick={() => setShow(!show)}
                        className="mx-2 mouseHover color-secondary"
                        icon={show ? faChevronUp : faChevronDown}
                        size="xs"
                    />
                </Col>
                <Col className={styles.analyticsCol}>
                    <DailyBadges reference="build" referenceId={build.id} />
                </Col>
                <Col className={styles.analyticsCol}>
                    <UserQueryBadges reference="build" referenceId={build.id} />
                </Col>
                <Col className={styles.infoIconCol}>
                    <FontAwesomeIcon
                        className="mouseHover pe-2 text-inverse-25"
                        icon={faInfoCircle}
                        onClick={() => handleShow(build)}
                    />
                </Col>
            </Row>
            <Collapse in={show}>
                <div>
                    <AnalyticsNetworkBuilds build={build} />
                </div>
            </Collapse>
        </div>
    );
};

export const FunnelBatchBuild = React.memo(FunnelBatchBuildImplementation);
